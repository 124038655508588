.config-name,.config-min,.config-max,.config-default,.config-data-type{
    position: relative;
    width: 100%;
}
.added-configs{
    width:100%
}
.selected-config{
    width: 90%;
    display: flex;
    align-items: flex-start;
    gap:0.4vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}
.single-selected-config{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0.20vw 0.7vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    background: #383838;
    color: white;
}
.single-selected-config>div{
    width:100%
}
.config-div{
    width: 100%;
}
.to-be-added,.configs-list{
    display: flex;
    align-items: center;
    padding: 0.23vw 0.7vw;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 1.87vw;
    gap:10%
}
.configs-list{
    padding: 0.15vw 0.7vw;
    /* width: 100%; */
}
.config-data-type-div{
    margin-top: 5%;
}
.selected-data-type{
    width: 95.5%;
    display: flex;
    padding: 2%;
    border-radius: 4px;
    background: #383838;
    color: white;
    border: 1px solid #D0D5DD;
    justify-content: space-between;
    font-size: 0.8vw;
    height: 1vw;
}
.selected-data-type>div{
    display: flex;
    align-items: center;
}
.open-config{
    /* position: relative; */
    font-family: inherit;
    font-size: 0.8vw;
    margin-top: 2%;
    max-height: 35vw;
    /* opacity: 1; */
    padding: 6% 4%;
    /* padding-left: 1%; */
    /* padding-right: 1%; */
    border-radius: 4px;
    background: #777777;
    overflow: auto;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.close-config{
    /* position: relative; */
    font-size: 0.8vw;
    margin-top: 0%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    padding: 0% 2%;
    /* padding-left: 1%; */
    background: #777777;
    border-radius: 4px;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s,margin-top 0.8s;
}
.config-min-max-value{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:9%;
    margin-top: 5%;
}
.config-default{
    margin-top: 5%;
}
.config-name input,.config-min input,.config-max input,.config-default input{
    width: 95.5%;
    padding: 2%;
    font-size: 0.8vw;
    border-radius: 4px;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    font-weight: inherit;
}
.config-min input,.config-max input{
    padding: 4%;
    width: 91%;
}
.config-name input:focus{
    outline: none;
}
.add-config-btn{
    margin-top:10%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4%
}
.add-config-btn>button{
    min-width: 30%;
    padding: 2%;
    border: none;
    border-radius: 4px;
    border:1px solid white;
    background-color: transparent;
    color: white;
    font-size: 0.8vw;
}
.add-config-btn>button:nth-child(2){
    font-weight: 800;
    min-width: 30%;
    padding: 2%;
    border: 1px solid #1AA053;
    border-radius: 4px;
    background: #1AA053;
    color: white;
    font-size: 0.8vw;
}
input:focus{
    outline: none;
}

input{
    font-family: inherit;
}