.bounce-in-top{
    width: 30vw;
    background: white;
    padding: 10px;
    border-radius: 5px;
    height: 24vw;
}
.lock-icon-div{
    height: 5vw;
    width:5vw;
    border-radius: 50%;
    margin: auto;
    background: #FFC2BE;
    border: 8px solid #FFE5E3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}
.lock-icon{
    font-size: 2vw;
    color:#A83018;
}
.heading-alert-box-dev{
    color: black;
    font-size: 1.4vw;
    font-weight: bolder;
    
    width:70%;
    margin: auto;
    margin-top: 5%;
}
.sub-heading-alert-box{
    color: rgba(0, 0, 0, 0.584);
    font-weight: 600;
    font-size: 0.8vw;
    width: 90%;
    margin: auto;
    margin-top: 3%;
}
.alert-box-ok-btn{
    margin-top: 9%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.to-be-login {
    background-color: yellow;
    color: black;
    border: 1px solid yellow;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 5px;
    width: 8vw;
    padding: 0.6vw 0;
}

.login-cancel {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 5px;
    width: 8vw;
    padding: 0.6vw 0;
}

.bounce-in-top {
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-28 16:48:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}