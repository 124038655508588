.section-progress-div{
    width:100%;
    height: 6vw;
    margin-top: 3%;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
}
.incomplete-section-progress-bar{
    position: relative;
    width: 90%;
    margin: auto;
    height: 0.7vw;
    background: #F8FAFB;
    border-radius: 12px;
}
.completion-balls-div{
    width:105%;
    position: absolute;
    top:-210%;
    left:-2.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}
.completion-ball{
    position: relative;
    height: 3.5vw;
    width: 3.5vw;
    border-radius: 50%;
    background: #F8FAFB;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
    font-size: 1.5vw;
    transition: background 0.5s,color 0.5s;
}
.sec-name{
    width:250%;
    position: absolute;
    top:110%;
    left:-75%;
    font-size: 0.9vw;
    font-weight: normal;
    color: white;
}
#present-section{
    background: #1AA053;
    color: white;
    transition: background 0.5s ease,color 0.5s ease;
    transition-delay: 0.6s;
}
.done-section{
    font-size: 2vw;
}
.completion-bar-div-1{
    position: absolute;
    width: 0%;
    left:0;
    top:0;
    background: #1AA053;
    height: 0.7vw;
    z-index: 0;
    transition: width 1s ease;
    
}
.completion-bar-div-2 {
    position: absolute;
    width: 33.33%;
    left: 0;
    top: 0;
    background: #1AA053;
    height: 0.7vw;
    z-index: 0;
    transition: width 1s ease;
    
}
.completion-bar-div-3 {
    position: absolute;
    width: 66.66%;
    left: 0;
    top: 0;
    background: #1AA053;
    height: 0.7vw;
    z-index: 0;
    transition: width 1s ease;
    
}
.completion-bar-div-4 {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: #1AA053;
    height: 0.7vw;
    z-index: 0;
    transition: width 1s ease;
}