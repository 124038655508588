.add-models {
  width: 100%;
  margin: auto;
  margin-top: 2%;
}
.add-models > div {
  flex: 1;
}
.model-heading {
  font-size: 1.4vw;
  color: #c6c6c6;
}
.model-submission-form-part {
  text-align: left;
}
.model-submission-form-part form {
  padding-bottom: 20px;
}
.model-submission-form-part form > div:nth-child(1) {
  display: flex;
  /* align-items: center; */
  justify-content: space-center;
  font-size: 1.1vw;
  gap: 10%;
  color: white;
  /* margin: 2% 0% 2% 0%; */
}
.model-submission-form-part form > div:nth-child(2) {
  display: flex;
  /* align-items: center; */
  justify-content: space-center;
  gap: 5%;
  color: white;
  /* margin: 2% 0% 2% 0%; */
}
.model-submission-form-part form > div > div {
  flex: 1;
}
.model-submission-form-part form > div > div > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
}
.info-icon{
  position: absolute;
  left:100%;
  top:20%;
  padding-left: 1%;
  color: rgb(198, 198, 198);
}
.info-icon:hover{
  cursor: pointer;
}
.info-icon-inside-div{
  position: relative;
}
.submit-info{
  position: absolute;
  width: 20vw;
  top:-16%;
  right:120%;
  background-color: rgba(255, 255, 255);
  color: black;
  font-size: 0.8vw;
  padding: 0.6vw 1vw;
  border-radius: 3px;
}
.submit-info-content{
  position: relative;
}
.arrow-mark{
  position: absolute;
  height: 1vw;
  width:1vw;
  top:0;
  left:102%;
  /* border:1px solid red; */
  background-color: rgba(255, 255, 255);
  transform: rotate(45deg);
}
.model-submission-form-part form > div > div > div > div:nth-child(1) {
  flex: 1;
  font-size: 1vw;
  /* margin-top: 7%; */
}
.model-submission-form-part form > div > div > div > div:nth-child(2) {
  flex: 2;
  /* margin-top: 7%; */
}
.button-option-div {
  margin-top: 0%;
  padding-left: 0%;
  display: flex;
  gap: 20px;
  width: 50%;
  border: 1px solid red;
}
.button-option-div > button {
  width: 10vw;
  background-color: black;
  color: #7e7e7e;
  border: 1px solid #7e7e7e;
  border-radius: 10px;
  padding: 10px 20px;
}
.button-option-div > button:hover {
  background: #333333;
  cursor: pointer;
  color: white;
}
.model-submission-form-part input {
  width: 100%;
  /* margin-top: 2%; */
  padding: 10px 20px;
  border: 1px solid #2d343f;
  border-radius: 4px;
  color: #8997a5;
  background: rgba(217, 217, 217, 0.04);
}
form>div{
  width: 94%;
  margin: auto;
  margin-top: 3%;
}
.model-submission-form-part input::placeholder {
  color: #8997a5;
  opacity: 1;
}
.model-submission-form-part input:focus {
  outline: none;
}
.makeitInput {
  background: rgba(217, 217, 217, 0.04);
  /* partition lines */

  border: 1px solid #2d343f;
  border-radius: 4px;
  position: relative;
}
.makeitInput > div {
  color: #8997a5;
}
.makeitInput > div > div {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visiblity-drop-down {
  background-color: rgb(17, 19, 31);
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  border: 1px solid #7e7e7e;
  border-radius: 4px;
  padding: 5px 10px;
  z-index: 20;
}
.visiblity-drop-down > div {
  padding: 10px 10px;
  width: 100%;
  color: #7e7e7e;
  border-radius: 4px;
  background-color: #303030;
  color: white;
  margin: 10px 0px;
}
.visiblity-drop-down > div:hover {
  cursor: pointer;
}
.submit-btn-div-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
}
.submit-btn {
  font-size: 1vw;
  width: 10vw;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  background: #3e9f53;

  color: white;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 10px 0px;
}
.submit-btn {
  background-image: linear-gradient(
    to right,
    #3e9f53 0%,
    #0f7425 51%,
    #3e9f53 100%
  );
  margin: 10px;
  /* padding: 15px 45px; */
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 10px;
  display: block;
}

.submit-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.cancel-input-btn,
.save-input-btn {
  font-size: 1vw;
  padding: 10px 0px;
  width: 10vw;
  border: 1px solid #8997a5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  background-color: transparent;

  color: #8997a5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.submission-notification-part {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-submission {
  height: 19vw;
  width: 28vw;
  border-radius: 10px;
  background-color: white;
  position: relative;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-sub-text {
  width: 80%;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 31px;
  text-align: center;
  color: rgba(124, 134, 145, 1);
  margin: auto;
}
.pop-sub-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9vw;
  width: 9vw;
  border-radius: 50%;
  box-sizing: border-box;
  background: #50a33b;
  z-index: 10;
  position: absolute;
  top: -23%;
}
.pop-sub-icon > img {
  width: 50%;
  height: 40%;
}
.submission-notification-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.model-submission-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(52, 64, 84,0.6);
  z-index: 9;
}
.demo-thing {
  position: fixed;
  top: 30%;
  left: 42.6%;
  z-index: 50;
}
.selected-configs {
  max-width: 100%;
  background: rgba(217, 217, 217, 0.04);
  /* partition lines */

  border: 1px solid #2d343f;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #8997a5;
}
.selected-configs > div:nth-child(1) {
  align-items: center;
  gap: 10px;
  width: 80%;
  font-size: 1vw;
  /* display: flex;
  align-items: center;
  gap:2px */
}
.selected-configs>div:nth-child(1) span{
  font-size: 0.8vw;
  font-weight: bold;
}
.selected-configs > div:nth-child(2) {
  width: 20%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #7e7e7e;
}
.add-info-icon {
  color: #8997a5;
  font-size: 1.2vw;
}
.add-info-icon:hover {
  cursor: pointer;
}
.addition-info-pop-bx {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}
.additional-info-heading {
  font-style: normal;
  font-size: 1.4vw;
  color: white;
  font-weight: 800;
}
.addition-info-pop-bx > .config-part,.addition-info-pop-bx>.cloud-part {
  color: white;
  /* max-width: fit-content; */
  margin: auto;
  height: auto;
  background: #0F172A;
  padding: 2vw 5vw;
  overflow-y: auto;
  border-radius: 1vw;
}
/* .addition-info-pop-bx > .cloud-part{
  min-width: 30%;
  margin: auto;
  height: auto;
  background: #0F172A;
  color: white;
  padding: 2vw 5vw;
  overflow-y: auto;
  border-radius: 1vw;
} */

.cloud-part > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cloud-part>div:nth-child(2),.cloud-part>div:nth-child(3),.cloud-part>div:nth-child(4),.cloud-part>div:nth-child(5){
  font-size: 0.8vw;
}
.addition-info-pop-bx > .config-part > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  font-size: 1.1vw;
}
.addition-info-pop-bx > .config-part > div label {
  font-weight: 500;
  font-size: 0.8vw;
}
.cancel-additional-info-btn {
  width: 100px;
  border: 1px solid #181818;
  border-radius: 4px;
  background-color: white;
  font-size: 0.7vw;
  padding: 10px;
}
.add-additional-info-btn {
  width: 100px;
  border: none;
  background: #3e9f53;
  border: 1px solid #3e9f53;
  border-radius: 4px;
  font-size: 0.7vw;
  padding: 10px;
  color: white;
}
.addition-info-pop-bx > .config-part > div input {
  width: 100%;
  border: none;
  background: white;
  border-radius: 4px;
  padding: 7px;
  color: balck;
  font-size: 0.9vw;
}
.addition-info-pop-bx > .config-part > div input:focus {
  outline: none;
}
.area-to-show-configs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6vw, 1fr));
  gap: 5px;
  grid-auto-flow: dense;
  font-size: 0.8vw;
}
.area-to-show-config-paceholder {
  width: 100%;
  
}
.added-config {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #303030;
  padding: 0.2vw 0.7vw;
  font-size: 0.9vw;
  border-radius: 5px;
  gap: 3px;
}
.select-number-of-band{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9vw;
  position: relative;
}
.min-max-inputs{
  position: absolute;
  top:120%;
  right:0;
  font-size: 0.6vw;
  color: gold;
}
.select-number-of-band input{
  width:30%;
  background-color: white;
  padding: 3px 10px;
  border-radius: 4px;
  color:black;
  border:none
}
.select-number-of-band input:focus{
  outline: none;
}
.check-box-false {
  font-size: 1.1vw;
    color: rgb(165, 201, 202);
}
.check-box-true {
  font-size:1.1vw;
  color: rgb(165, 201, 202);
}
.all-band-color {
  width: 100%;
}
.band-number {
  font-size: 20px;
  font-weight: 600;
}
.all-band-color > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.band-options > div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.active-color {
  color: black;
  background-color: #3e9f53;
}
.inactive-band-color {
  color: #0076ed;
}
.color-list{
  border: 1px solid rgb(57, 91, 100);
}
.color-list button {
  width: 100%;
  border: none;
  font-size: 12px;
}
.output-band-selection {
  width: 100%;
}
.output-band-selection-heading {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
}
.output-band-selection-heading > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.select-type {
  width: 100%;
  position: relative;
  border:1px solid rgb(57, 91, 100);
  border-radius: 4px;
  padding: 10px;
}
.selected-type-band {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.number-of-types {
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 2px solid #3e9f53;
  border-radius: 4px;
}
.active-classification-type,
.active-object-detection,
.active-object-classification,
.active-image-type {
  padding: 10px;
  background-color: #3e9f53;
  color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 150px;
}
.inactive-classification-type,
.inactive-object-detection,
.inactive-object-classification,
.inactive-image-type {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #181818;
  margin-bottom: 10px;
  min-width: 150px;
}
.number-of-types > div {
  padding: 5px 10px;
}
.number-of-types > div:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.add-band-type {
  width: 100%;
}
.number-of-types {
  text-align: left;
}
.band-options {
  display: flex;
  flex-direction: column;
  gap:10px;
  align-items: center;
  width: 100%;
  margin: auto;
  font-size: 0.9vw;
  margin-top: 10%;
}
.band-selection {
  width: 200px;
  position: relative;
}
.selected-band-color {
  width: 70%;
  border: 1px solid rgb(57, 91, 100);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
}
.color-list {
  position: absolute;
  top: 0%;
  left: 72%;
  width: max-content;
  border: 1px solid rgb(57, 91, 100);
  background: #1e212d;
  z-index: 4;
  border-radius: 4px;
  font-size: 0.8vw;
  padding: 2px 1px;
}
.color-list>div>button{
  font-size: 0.8vw;
  background-color: transparent;
  color: rgb(165, 201, 202);
}

.color-list>div>button:hover{
  background-color: rgb(57, 91, 100);
}
/* New Deploment Popup css */
.deployment{
  height: 100%;
  overflow-y: auto;
}
.deployment-pop{
  position: fixed;
  top:0;
  left:0;
  Width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.deployment-overlay{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.616);
}
.deployment-content{
  z-index: 2;
}
.submit-failure-overlay{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.616);
  z-index: 10;
}
.submit-failure-content-box{
  position: fixed;
  top:0;
  left:0;
  width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.to-be-flexed-input{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:2px
}
.to-be-flexed-input>div:nth-child(1){
  flex:3
}
.to-be-flexed-input>div:nth-child(2){
  flex:1
}
.activated-data-type-config{
  background-color: rgb(29, 120, 29);
  color: white;
}