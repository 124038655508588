.model-log{
  background: rgba(52, 64, 84, );
    border-radius: 4px;
    padding: 1.7vw 1.7vw 1.7vw 1.7vw;
    width: 40%;
    margin: auto;
}
.model-log-text-head{
  font-size: 1vw;
}
.close-icon-log{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.log-table{
   background: rgb(34, 34, 34);;
   padding: 1vw;
   font-size: 0.8vw;
   min-height: 10vw;
   max-height:40vw ;
   overflow: auto;
}
.model_id_log{
  font-size: 0.8vw;
  background: #272727;
  color: white;
  font-weight: 500;
  padding: 0.5vw 0.7vw;
  margin-top: 2%;
  display: flex;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.closing-icons-font:hover{
  cursor: pointer;
}
.a-circle{
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 9px;
}
.i-red{
  background-color: rgb(255, 95, 87);
  margin-left: 0.4vw;
}
.i-green{
  background-color: rgb(40, 200, 64);
  
}
.i-yellow{
  background-color: rgb(254, 188, 46);
}
