.active-color{
    background-color: red;
}
.select-color-div{
    width: 100%;
}
.input-band-div{
    width: 100%;
    position: relative;
}
.input-band-selection{
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    gap:5%
}
.input-band-selection input{
    /* width: 83%; */
    padding: 0.5vw 0.7vw;
    font-size: 0.8vw;
    border-radius: 4px;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
}
.input-band-selection input:focus{
    outline: none;
}
.selected-input-bands,.selected-color{
    display: flex;
    align-items: center;
    padding: 0vw 0.7vw;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 2vw;
    gap:10%
}
.selected-color{
    /* width: 83%; */
}
.selected-input-bands>div:nth-child(1){
    width: 90%;
    margin-top: 0.01vw;
    min-height: 2.2vw;
    display: flex;
    align-items: center;
}
.selected-input-bands>div:nth-child(2) {
    width: fit-content;
    display:flex;
    align-items: center;
    justify-content: flex-end;
}
.selected-color{
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
}
.open-input-selection{
    position: relative;
    font-size: 0.8vw;
    margin-top: 2%;
    max-height: 35vw;
    /* opacity: 1; */
    padding: 6% 4%;
    /* padding-left: 1%; */
    /* padding-right: 1%; */
    border-radius: 4px;
    background: #777777;
    overflow: auto;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.close-input-selection{
    position: relative;
    font-size: 0.8vw;
    margin-top: 0%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    padding: 0% 2%;
    /* padding-left: 1%; */
    background: #777777;
    border-radius: 4px;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s,margin-top 0.5s;
}
.bands-selected{
    padding: 0.20vw 0vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    color: white;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.4vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}
.band-not-selected-text{
    height: 100%;
    display: flex;
    align-items: center;
}
.bands-selected>div{
    width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 2vw;
        padding: 0.20vw 0.7vw;
        border-radius: 0.6vw;
        font-size: 0.8vw;
        background: #383838;
        color: white;
        margin-left: 1.5%;
}
.number-of-bands{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    gap:7%;
    margin-top: 10%;
    height: max-content;
}

.open-list-of-colors{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 62%;
    padding: 0.3vw;
    border-radius: 4px;
    overflow: hidden;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    transition: height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
}

.close-list-of-colors{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    padding: 0 0.3vw;
    border-radius: 4px;
    background: #383838;
    border: 0px solid #D0D5DD;
    color: white;
    transition: height 0.5s ease, opacity 0.5s ease, padding 0.5s;
}
#disabled-color:hover, #disabled-color{
    cursor: not-allowed;
    color: gray;
}