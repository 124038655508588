.warning-text{
    width:80%;
    margin: auto;
    margin-top: 10%;
    font-size: 22px;
    color: rgba(124, 134, 145, 1);
    font-weight: 800;
}
.action-btn-div{
    
    width:90%;
    margin: auto;
    margin-top: 10%;
    background: #F1F5F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 15px;;
    display: flex;
    justify-content: space-between;
    align-items:center ;
}
.cancel-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    width: 165px;
    height: 42px;
    background: #D0DEEB;
    border-radius: 4px;
    border:none;
    font-size: 18px;
    color: #9BA9B9;
    font-family: inherit;
}
.confirm-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    width: 165px;
    height: 42px;
    background: #50A33B;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    color: white;
    font-family: inherit;
}