.active-lop{
    background-color: red;
}
.lop{
    width: 100%;
}
.lop-selection-div{
    width: 100%;
    position: relative;
}
.selected-lop{
    display: flex;
    padding: 1.8%;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 1.5vw;;
}
.selected-lop>div{
    display: flex;
    align-items: center;
}
.lop-lists-open{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 8.5vw;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.lop-lists-close{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    background: #383838;
    border: 0px solid #D0D5DD;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}