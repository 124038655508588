.tracker-model-text{
    width:max-content;
    text-align: left;
    position: relative;
    margin-top: 2%;
    font-size: 1vw;
}
.image-ss{
    width:105%;
    margin-left: -3%;
    margin-top: 3%;
}