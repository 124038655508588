.success-div{
    width:30vw;
    background-color: white;
    color: black;
}
.right-sign-div{
    height: 7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3f852d;
}
.right-sign-img-div{
    width:5vw;
    height: 5vw;
    border-radius: 50%;
    border:3px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-sign-img-div img{
    width:75%
}
.heading-msg{
    color: black;
    margin-top: 6%;
    font-weight: 900;
    font-size: 1.7vw;
}
.success-msg{
    width: 80%;
    margin: auto;
    font-size: 0.9vw;
    margin-top: 1%;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.605);
}
.register-ok{
    margin-top: 7%;
    margin-bottom: 7%;
    border:none;
    background-color: #3f852d;
    color: white;
    font-size: 1vw;
    padding: 5px 10px;
    width:40%
}