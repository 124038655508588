.model-type-selection-div{
    position: relative;
    margin-top: 0.4%;
}
.model-type{
    width:100%;
    position: relative;
}
.model-type-selected{
    width: 96%;
    display: flex;
    padding: 1% 2%;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    border: 1px solid #D0D5DD;
    min-height: 1.87vw;;
}
.model-type-selected>div{
    display: flex;
    align-items: center;
}
.model-type-selected:hover{
    cursor: pointer;
}
.goUp{
    transform: rotate(180deg);
    transition: transform 0.5s;
    width: min-content;
}
.goDown{
    transform: rotate(0deg);
    transition: transform 0.5s;
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.model-type-lists-open{
    position: relative;
    font-size: 0.8vw;
    margin-top:1%;
    max-height: 5.1vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    color: white;
    border: 1px solid #D0D5DD;
    transition: max-height 0.5s ease,opacity 0.5s ease,padding-top 0.5s ease,padding-bottom 0.5s ease;
}
.model-type-lists-close{
    position: relative;
    font-size: 0.8vw;
    /* margin-top: 1%; */
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color: white;
    border:0px;
    transition: max-height 0.5s ease, opacity 0.5s ease,padding-top 0.5s,border 2s ease,margin-top 0.9s;
}
.single-type{
    padding: 1% 1%;
}
.single-type:hover{
    background-color: #484848;
    cursor: pointer;
}
#bg-gray{
    background-color: rgb(92, 91, 94);
    color: white;
    border : 1px solid rgb(92, 91, 94)
}