.notification {
  width: 95%;
  margin: auto;
  margin-top: 2%;
  text-align: left;
  
}
.notification-content{
  width: 100%;
    height: 77vh;
    overflow-y: auto;
}
.notify-heading {
  font-size: 1vw;
  position: relative;
  width: fit-content;
}
.underline{
  position: absolute;
  top: 110%;
  height: 0.2vw;
  width: 100%;
  background-color: gold;
}
.notify-heading {
  margin-bottom: 2%;
}
.notication-page-close {
  font-size: 1.4vw;
}
.msg {
  margin-top: 15px;
  border-radius: 4px;
  color: rgb(30, 86, 30);
  width:80%;
  background-color: rgb(177, 206, 177);
  border:1px solid rgb(30, 86, 30)
}
.msg-red{
  margin-top: 15px;
  border-radius: 4px;
  color: #F44336;
  width: 80%;
  background-color: #FFEBEE;
  border: 1px solid #F44336
}
.grayedOut-notification{
  margin-top: 15px;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  width: 80%;
  background-color: rgb(201, 201, 201);
  border: 1px solid rgb(106, 106, 106)
}
.msg-content{
  font-size: 0.9vw;
  width: 100%;
  height: 100%;
  padding: 1vw 1vw;
}
.msg-content:hover{
  /* font-weight: bold; */
  cursor: pointer;
}
.remove-icons {
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
}
.successfull {
  background: #c6eccb;
}
.error {
  background: #fff5f3;
}
.warning {
  background: #ffe4a7;
}
.all-notifications{
  background: #E3F2FD;
  border: 1px solid #2196F3;
  padding: 1vw 1vw;
  width:80%;
  border-radius: 7px;
}
.no-notify-head{
  display: flex;
  align-items: center;
  gap:1vw
}
.warn-icon{
  color:#2196F3;
  margin-top: -0.3%;
}
.notify-head{
  color: #263238;
  font-size: 1vw;
  font-weight: bold;
}
.no-notify-sub-head{
  color: #979797;
  font-size: 0.8vw;
  margin-top: 2%;
  margin-left: 3.5%;
}
.timestamp-size{
  font-size: 0.7vw;
  letter-spacing: 0.1vw;
}