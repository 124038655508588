.model-path{
    position: relative;
    width: 100%;
}
.model-path input {
    width: 96%;
    padding:1% 2%;
    font-size: 0.8vw;
    border: none;
    border-radius: 4px;
    min-height: 1.87vw;;
    font-family: inherit;
}

.model-path input:focus {
    outline: none;
}