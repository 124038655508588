.output-div{
    width:100%;
    font-size: 0.85vw;
    text-align: left;
}
.output-div>div{
    width:100%;
    min-height:1.3vw;
}
.output-name{
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
.output-name input{
    width: 100%;
    padding: 0.7vw;
    font-size: 0.8vw;
    border-radius: 4px;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    box-sizing: border-box;
}
.disabled-cursor:hover{
    cursor: not-allowed;
}
.output-name input:focus{
    outline: none;
}
.selected-outputs{
    display: flex;
    align-items: center;
    padding: 0vw 0.7vw;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 2.3vw;;
    gap:10%;
}
/* .selected-outputs>div:nth-child(1){
    padding: 0.20vw 0vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    color: white;
    width: 90%;
    display: flex;
    align-items: flex-start;
    gap: 0.4vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
} */
.outputband-list-div{
    padding: 0.20vw 0vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    color: white;
    width: 90%;
    display: flex;
    align-items: flex-start;
    gap: 0.4vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}

.outputband-not-list{
    width: 100%;
    display: flex;
    align-items: center;
}
.outputband-list-div>div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0vw 0.7vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    background: #383838;
    color: white;
}
.single-selection-output{
    width:100%;
    height: 100%;
    padding: 0.42vw 0vw;
}
.open-output-selection-box{
    position: relative;
    font-size: 0.8vw;
    margin-top: 2%;
    max-height: 35vw;
    /* opacity: 1; */
    padding: 6% 4%;
    /* padding-left: 1%; */
    /* padding-right: 1%; */
    border-radius: 4px;
    background: #777777;
    overflow-y: auto;
    overflow-x: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
    z-index: 10;
}
.close-output-selection-box {
    position: relative;
    font-size: 0.8vw;
    margin-top: 2%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    padding: 0% 2%;
    /* padding-left: 1%; */
    background: #777777;
    border-radius: 4px;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s;
    z-index: 10;
}
.output-selection-div{
    width:100%;
    margin-top: 5%;
}
.selected-output{
    position: relative;
    display: flex;
    padding: 1.8%;
    border-radius: 4px;
    justify-content: space-between;
    font-size: 0.8vw;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    height: 1.5vw;
}
.selected-output>div{
    display: flex;
    align-items: center;
}
.all-output-types{
    /* position: relative; */
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 9vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #D0D5DD;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.all-output-types-close{
    /* position: relative; */
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}
.output-suffix-details{
    position: absolute;
    top:100%;
    left:0;
    width: 100%;
    color: rgb(62, 62, 209);
    text-decoration: underline;
    z-index: 1;
    font-weight: bold;
    opacity: 1;
    transition: opacity 2s;
    visibility: visible;
}
.output-suffix-details-hide{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color: rgb(62, 62, 209);
    text-decoration: underline;
    z-index: 1;
    font-weight: bold;
    opacity: 0;
    visibility: hidden;
}
.output-suffix-details>p{
    width: 100%;
}