
.selected-dtype{
    display: flex;
    padding: 2%;
    border-radius: 4px;
    background: #383838;
    color: white;
    justify-content: space-between;
    font-size: 0.8vw;
    border: 1px solid #D0D5DD;
    height: 1.5vw;
}
.selected-dtype>div{
    display: flex;
    align-items: center;
}
.open-dtype {
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 10vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    color: white;
    border: 1px solid #D0D5DD;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}

.close-dtype {
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}
.class-number-selection{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7%;
}
.class-number-selection input{
    padding: 0.7vw;
    font-size: 0.8vw;
    border-radius: 4px;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
}
.class-selection{
    margin-top: 10%;
    background-color: white;
    border-radius: 4px;
}
.class-selection-heading{
    color: #000000;
    display: flex;
    align-items: center;
    height: 3vw;
}
.class-selection-heading>div:nth-child(1),.single-class-div>div:nth-child(1){
    width:25%;
    text-align: center;
}
.class-selection-heading>div:nth-child(2),.single-class-div>div:nth-child(2) {
    width: 75%;
    text-align: center;
}
.class-selection-content{
    background: #383838;
    color: white;
}
.single-class-div{
    display: flex;
    align-items: center;
    height: 3vw;
    border: 1px solid white
}
.select-dtype{
    position: relative;
}
.single-class-div>div:nth-child(1){
    border-right: 1px solid white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-class-div>div{
    height: 100%;
}
.single-class-div>div input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: white;
    border: none;
    padding-left: 10%;
}