.prev-page{
    width:100%;
    max-height: 30vw;
    padding: 2vw 4vw;
    background: #0F172A;
    border-radius: 0.4vw;
    /* border:1px solid red */
}
.image-preview-box{
    max-height: 20vw;
    overflow-y: scroll;
    margin-top: 5%;
    min-width: 30vw;
}
.prev-content{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between;
    /* height: 80%; */
    overflow-y: auto;
    gap:10px
}
.prev-content>div:nth-child(1){
    width: 12%;
}
.prev-content>div:nth-child(2){
    width: 15%;
    font-size: 0.6vw;
    overflow: hidden;
    /* border: 1px solid red; */
}
.prev-content>div:nth-child(3) {
    width: 6vw;
    /* border: 1px solid red; */
}
.cloudmask-download-btn {
    width: 8vw;
    /* border: 1px solid red; */
}
.cloudshadowmask-download-btn {
    width: 11vw;
    /* border: 1px solid red; */
}
.nodatamask-download-btn {
    width: 10vw;
    /* border: 1px solid red; */
}
.prev-content>div:nth-child(7) {
    width: 2%;
    display: flex;
    justify-content: end;
    align-items: center;
    /* border: 1px solid red; */
}
.download-img-head{
    color: white;
    font-size: 1vw;
    text-align: left;
    font-weight: 800;
}
/* .image-prev-div{
    margin-right: 20px;
} */
.image-prev-div img{
    height: 5vw;
    width: 5vw;
}
.image-prev-link{
    font-size: 0.6vw;
    border:1px solid white;
    padding: 0.4vw 0.7vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:1vw;
    max-width: 100%;
    border-radius: 0.5vw;
    color: white;
}
.image-delete-div button{
    background-color: transparent;
    color: white;
    border:none;
}
.image-prev-pop-div-btn{
    margin-top: 1%;
}
.image-prev-pop-div-btn button:nth-child(1){
    background:transparent;
    border-radius: 4px;
    font-size: 0.8vw;
    color: #8997A5;
    padding: 0.5vw 1.3vw;
    margin-right: 10px;
    border:1px solid #8997A5;
}
.image-prev-pop-div-btn button:nth-child(2) {
    background: #36B368;
    border-radius: 4px;
    font-size: 0.8vw;
    color: white;
    padding: 0.5vw 0.7vw;
    border: 1px solid #36B368;
}
.preview-submission-overlay{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
}
.preview-submission-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.preview-img-id{
    font-size: 0.8vw;
}
.preview-underline{
    width: 100%;
    height: 1px;
    background-color: rgb(230, 229, 240);
    margin-top: 3%;
}
a:hover{
    text-decoration: none;
    color: #8997A5;
}