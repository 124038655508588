.dev-sidebar {
  background: #080a17;
  height: 100%;
  border-right: 1px solid #2d343f;
  overflow-y: auto;
}
.dev-sidebar > div {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  text-align: left;
  cursor: pointer;
}
.developer-page-options {
  padding: 5% 10%;
  color: white;
  font-weight: 300;
  /* width:80%; */
  border-bottom: 1px solid #2d343f;
  position: relative;
}
.developer-page-options > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2vw;
  
}
.developer-page-options:hover {
  background: rgb(36, 33, 0);
  background: linear-gradient(90deg, rgb(0, 7, 14) 0%, rgb(0, 24, 51) 85%);
  color: white;
}
#activepage {
  background: rgb(36, 33, 0);
  background: linear-gradient(90deg, rgb(0, 19, 40) 0%, rgb(0, 40, 86) 85%);
}
.cant-access-text{
  position: absolute;
  left: 10%;
  top:130%;
  background: #F64B3C;;
  z-index: 1;
  color: rgb(0, 0, 0);
  width:80%;
  display: flex;
  align-items: center;
  border-radius: 0.2vw;
}
#grayed-out{
  background-color: rgb(70, 70, 70);
  padding: 5% 10%;
  color: white;
  font-weight: 300;
  /* width: 100%; */
  border-bottom: 1px solid #2d343f;
  position: relative;
  font-size: 1.2vw;
}
#grayed-out:hover{
  cursor: not-allowed;
  background-color: rgb(84, 84, 84);
}
.notification-position{
  position: relative;
  left:30px;
  top:1px;
}
.notification-number{
  position: absolute;
  top:-30%;
  left:50%;
  height: 1vw;
  width: 1vw;
  background-color: red;
  color: white;
  font-size: 0.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
}