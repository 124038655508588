.masking-div{
    width: 100%;
}
.selected-masks{
    display: flex;
    align-items: center;
    padding: 0vw 0.7vw;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 1.5vw;;
}
/* .selected-masks>div:nth-child(2){
    display: flex;
    align-items: center;
} */
.open-masking-list{
    /* position: relative; */
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 8.5vw;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.close-masking-list{
    /* position: relative; */
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    background: #383838;
    border: 0px solid #D0D5DD;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}
.single-selected-mask{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 1.5vw;
    padding: 0.20vw 0.7vw;
    border-radius: 0.6vw;
    font-size: 0.8vw;
    background: #383838;
    color: white;
    margin-left: 1.5%;
}
.masking-area{
    width: 93%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 2vw;
    gap:0.3vw;
    text-align: left;
    padding: 0.2vw 0;
}
.selected-masking{
    width: 100%;
}