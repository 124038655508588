.progressbar-div {
  margin-top: 7%;
  width: 100%;
  margin-bottom: 80px;
}
.progress-area {
  height: 10px;
  background-color: rgba(151, 151, 151, 1);
  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 70px;
  /* position: relative; */
  /* border:1px solid red; */
}
.progress-area > div {
  flex: 1;
  overflow-x: hidden;
}
.progress-area > div > div {
  flex: 1;
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
/* .timestamp-box{
    position: absolute;
    top:18px;
    width:100%;
    text-align: center;
    visibility: visible;
    opacity: 1;
    transition-property: top,visibility,opacity;
    transition-duration:1s
} */
.passed {
  height: 8px;
  width: 8px;
  background-color: #50a33b;
  border-radius: 50%;
}
.genric {
  height: 8px;
  width: 8px;
  background-color: gray;
  border-radius: 50%;
}
/* .timestamp-box-notvisible{
    position: absolute;
    top: -15px;
    left:0px;
    width: 100%;
    visibility: hidden;
    text-align: center;
    opacity: 0;
    transition-property: top,visibility,opacity;
    transition-duration: 1s;
    
} */
.progress-text-area {
  width: 100%;
  height: 8vw;
  position: relative;
  overflow-y: hidden;
}
.timestamp-box {
  position: absolute;
  top: 5.5vw;
  visibility: visible;
  opacity: 1;
  /* transition-property: top,visibility,opacity;
    transition-duration: 1s; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.timestamp-box-notvisible {
  position: relative;
  top: 0%;
  opacity: 0;
  /* transition-property: top,opacity;
    transition-duration: 1s; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-show {
  position: relative;
  margin-top: 2%;
  /* transition-property: margin-top;
    transition-duration: 1s; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 5px;
  word-wrap: break-word;
  text-align: left;
}
.failed-circle {
  height: 8px;
  width: 8px;
  background-color: #bc312e;
  border-radius: 50%;
}

.text-not-show {
  position: absolute;
  margin-top: 6vw;
  /* transition-property: margin-top;
    transition-duration: 1s; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.first {
  height: 10px;
  background-color: #50a33b;
  /* width: 11.11%; */
  width:20%;
  transition-property: width;
  transition-duration: 1s;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  margin-top: 40px;
}
.first-unsubmit {
  height: 10px;
  background-color: #ed6d47;
  /* width: 11.11%; */
  width: 20%;
  transition-property: width;
  transition-duration: 1s;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
}
.second {
  height: 10px;
  background-color: #50a33b;
  /* width: 22.22%; */
  width: 40%;
  transition-property: width;
  transition-duration: 1s;
}
/* .third {
  height: 10px;
  background-color: #50a33b;
  width: 75%;
  transition-property: width;
  transition-duration: 1s;
} */
.fourth {
  height: 10px;
  background-color: #50a33b;
  /* width: 44.44%; */
  width: 80%;
  transition-property: width;
  transition-duration: 1s;
  /* border: 1px solid red; */
}

.without-hw {
  width: 100% !important;
}

.fourth-failed {

  height: 10px;
    background-image: linear-gradient(to right,
      #50a33b,
      #50a33b 75%,
      #ed6d47 25%);
    /* width: 55.55%; */
    width: 80%;
    transition-property: width, background-image;
    transition-duration: 1s;
}
.fourth-in-progress{
  height: 10px;
  /* width: 44.44%; */
  width: 80%;
  transition-property: width;
  background-image: linear-gradient(to right, #50a33b, #50a33b 75%, yellow 25%);
  /* animation: Progress 5s linear infinite; */
  position: relative;
}
.fifth{
  height: 10px;
  background-color: #50a33b;
  width: 100%;
  transition-property: width;
  transition-duration: 1s;
}
.fifth-failed{
  height: 10px;
  background-image: linear-gradient(to right,
      #50a33b,
      #50a33b 80%,
      #ed6d47 20%);
  width: 100%;
  transition-property: width, background-image;
  transition-duration: 1s;
}
.fifth-in-progress{
  height: 10px;
  width: 100%;
  transition-property: width;
  background-image: linear-gradient(to right, #50a33b, #50a33b 80%, yellow 20%);
  position: relative;
}
/* .progress-animation{
  position: absolute;
    top: 0;
    left: 0%;
    width: 25%;
    height: 100%;
    opacity: 0;
    background-image: yellow;
    border:1px solid red
} */
/* .sixth {
  height: 10px;
  background-color: #50a33b;
  width: 100%;
  transition-property: width;
  transition-duration: 1s;
} */
.third-failed {
  height: 10px;
  background-image: linear-gradient(
    to right,
    #50a33b,
    #50a33b 66.66%,
    #ed6d47 33.33%
  );
  /* width: 55.55%; */
  width: 60%;
  transition-property: width, background-image;
  transition-duration: 1s;
  /* border: 1px solid red; */
}
.third-passed {
  height: 10px;
  background-color: #50a33b;
  /* width: 55.55%; */
  width: 60%;
  transition-property: width;
  transition-duration: 1s;
  /* border: 1px solid red; */
}
.third-in-progress{
  height: 10px;
  /* width: 44.44%; */
  width: 60%;
  transition-property: width;
  background-image: linear-gradient(to right,
        #50a33b,
        #50a33b 66.66%,
        yellow 33.33%);
  /* animation: Progress 5s linear infinite; */
  position: relative;
}
/* .seventh {
  height: 10px;
  background-color: #50a33b;
  width: 77.77%;
  transition-property: width;
  transition-duration: 1s;
}
.eighth {
  height: 10px;
  background-color: #50a33b;
  width: 88.88%;
  transition-property: width;
  transition-duration: 1s;
}
.ninth {
  height: 10px;
  background-color: #50a33b;
  width: 100%;
  transition-property: width;
  transition-duration: 1s;
} */

.testing-progress{
  height: 12px;
  width: 12px;
  background-color: yellow;
  border-radius: 50%;
  animation-name: Blinking;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes Blinking {
  0%{
    box-shadow: 0px 0px 0px 0px yellow;
  }
  10%{
    box-shadow: 0px 0px 2px 0px yellow;
  }
  20%{
    box-shadow: 0px 0px 4px 1px yellow;
  }
  30%{
    box-shadow: 0px 0px 6px 1.5px yellow;
  }
  40%{
    box-shadow: 0px 0px 8px 2px yellow;
  }
  50% {
    box-shadow: 0px 0px 10px 2px yellow;
  }
  60% {
    box-shadow: 0px 0px 8px 2px yellow;
  }
  70% {
    box-shadow: 0px 0px 6px 1.5px yellow;
  }
  80% {
    box-shadow: 0px 0px 4px 1px yellow;
  }
  90% {
    box-shadow: 0px 0px 2px 0px yellow;
  }
  100% {
    box-shadow: 0px 0px 0px 0px yellow;
  }
}

@keyframes Progress {
  0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}