.section-single-box-not-pro,.sec-info{
    background: #1C1B1F;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
}
.sec1-div-left,.sec1-div-right{
    background: #1C1B1F;
    border-radius: 4px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;;
    gap:2.4vw;
    text-align: left;
    min-height: 32.2vw;
}
.sec-info-head{
    font-size: 1.07vw;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cross{
    transform: rotate(135deg);
    transition: transform 0.5s ease;
}
.plus{
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}
.sec1-div{
    display: flex;
    gap:5%;
    align-items: flex-start;
}
.sec1-div>div{
    flex:1
}
/* #sec2{
    margin-top: 10%;
} */
.sec1-1{
    margin-top: 5%;
}
.sec1-2{
    margin-top: 16%;
}
.sec1-3 {
    margin-top: 6.5%;
}
.sec1-4 {
    margin-top: 6%;
}
.sec-info{
    border-bottom:2px solid rgba(165, 165, 255, 0.15);
    padding-bottom: 0.5vw;
}
.sec-info-relative{
    position: relative;
    width: 100%;
}
.sec-info-desc{
    position: relative;
    max-height: 10vw;
    overflow: auto;
    transition: max-height 0.5s ease;
    font-size: 0.9vw;
    margin-top: 1%;
}
.sec-info-desc-close{
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    font-size: 0.9vw;
    margin-top: 1%;
}
.input-label{
    font-size: 0.85vw;
    line-height: 1.6vw;
}