.login-page-div {
    display: flex;
    width: 67%;
    height: 85vh;
    margin: auto;
    background-color: black;
    padding: 3%;
    overflow: hidden;
    /* border:1px solid red; */
    align-items: center;
    justify-content: center;
}

.login-page-logo {
    flex: 2;
}

.login-page-logo>img {
    width: 200px;
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 4% 7%;
}

.login-page-quotes {
    flex: 1;
    width: 180px;
    margin: auto;
    color: white;
    margin-top: 20%;
    font-weight: 600;
    /* border:1px solid red; */
}

.login-page-image {
    width: 54%;
    height: 90vh;
    background-image: url("../../Images/login.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    /* border:1px solid red */
}

.login-form-details {
    width: 38%;
    height: 90vh;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* border:1px solid red */
}

.signup-heading {
    font-weight: bold;
    font-size: 24px;
    margin-top: 10%;
    margin-bottom: 4%;
}

.signup-subheading {
    font-size: 13px;
}

.registation-form {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    width: 100%
}

.registation-form>form {
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.registation-form>form>input {
    width: 100%;
    background-color: transparent;
    color: white;
    padding: 20px 0px;
    border: none;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    font-family: inherit;
    font-size: 15px;
}

.registation-form>form>input:focus {
    outline: none;
}

.checkbox-developer-login {
    width:100%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18%;
}
.checkbox-developer-login>div{
    display: flex;
    align-items: center;
}
.login-btn-form {
    width: 100%;
    
}
.login-btn-form>button{
    width:100%;
    padding: 14px;
    font-size: 18px;
    font-family: inherit;
    font-weight: bold;
    color: white;
    background-color: #007AFF;
    transition: all 0.5s;
    border-radius: 10px;
    border: none
}
.login-btn-form-disabled{
    width: 100%;

}
.login-btn-form-disabled>button{
    width: 100%;
    padding: 14px;
    font-size: 18px;
    font-family: inherit;
    font-weight: bold;
    color: white;
    background-color: #919191;
    transition: all 0.5s;
    border-radius: 10px;
    border: none
}
.login-btn-form-disabled>button:hover {
    cursor: not-allowed;
    background-color: #6b6b6b;
    transition: all 0.5s;
}

.account-logined {
    margin-top: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-link{
    padding-left: 2%;
}
.error-login{
    text-align: center;
    font-size: 0.9vw;
    color: red;
    margin-top: -5%;
}
.login-link>a {
    color: #007AFF;
}
.signin-mobile-logo{
    display: none;
}
.checkbox-input{
    background-color: transparent;
}
.term-of-service-text{
    font-size: 0.8vw;
    line-height: 1.3vw;
    letter-spacing: 0.1vw;
}
.grayed-out-text{
    color: rgba(192, 192, 192, 0.756);
}
.terms-of-service-text-color{
    color:gold
}
.terms-of-service-text-color:hover {
    color: gold;
    text-decoration: underline;
}
@media only screen and (max-width: 768px) {
    .login {
        display: flex;
        width: 90vw;
        height: 90vh;
        margin: auto;
        margin-top: 0vh;
        overflow: hidden;
        background-color: black;
    }
    .login-image{
        display: none;
    }
    .signin-mobile-logo {
        display: flex;
        width:140px;
    }
    .signin-mobile-logo>img{
        width:100%
    }
    .login-form-details {
        width: 90%;
        height: auto;
        margin: auto;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .login-btn-form {
        width: 100%;
        
    }
    .login-btn-form>button{
        width:100%;
        margin: auto;
        padding: 14px;
        font-size: 18px;
        font-family: inherit;
        font-weight: bold;
        color: white;
        background-color: #007AFF;
        transition: all 0.5s;
        border-radius: 10px;
        border: none
    }
    .login-btn-form>button:hover {
        cursor: pointer;
        background-color: #005abb;
        transition: all 0.5s;
    }
    .signup-heading{
        font-size: 30px;
    }
    .signup-subheading {
        font-size: 10px;
    }
    .term-of-service-text {
        font-size: 2vw;
        line-height: 1.3vw;
        letter-spacing: 0.1vw;
    }

    .grayed-out-text {
        color: rgba(192, 192, 192, 0.756);
    }

    .terms-of-service-text-color {
        color: gold
    }
}