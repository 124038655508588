.tooltip-div{
    position: absolute;
    top:100%;
    left:0%;
    z-index: 100;
    min-width:15vw;
    display: flex;
    align-items: center;
    font-size: 0.8vw;
    text-align: left;
    opacity: 1;
}
.tooltip-content-div{
    min-width: fit-content;
    position: relative;
    margin-top: 2%;
    padding: 0.3vw;
    color: white;
    background-color: rgb(62, 62, 209);
    display: flex;
    align-items: center;
    gap:10px;
    border-radius: 0.2vw;
}
.tooltip-content-div-white{
    min-width: fit-content;
    position: relative;
    padding: 0.3vw;
    margin-top: 5%;
    color: black;
    margin-left: -3.7%;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 0.2vw;
}
.pointer-to-icon{
    position: absolute;
    height: 0.7vw;
    width: 0.7vw;
    background-color: rgb(62, 62, 209);
    transform: rotate(45deg);
    top:-10%;
    left: 15px;
}
.pointer-to-icon-white{
    position: absolute;
    height: 0.7vw;
    width: 0.7vw;
    background-color: white;
    transform: rotate(45deg);
    top: -12%;
    left: 15px;
}
.informative-btn{
    color: white;
    font-size: 1vw;
}