.selected-seg-data-type{
    display: flex;
    padding: 2%;
    border-radius: 4px;
    background: #383838;
    color: white;
    justify-content: space-between;
    font-size: 0.8vw;
    border: 1px solid #D0D5DD;
    height: 1.5vw;
}
.selected-seg-data-type>div{
    display: flex;
    align-items: center;
}
.open-list-seg-data-type{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 7vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    color: white;
    border: 1px solid #D0D5DD;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.close-list-seg-data-type{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color: white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}