.tracker-page {
  text-align: left;
  width: 100%;
  margin: auto;
  padding-top: 2%;
  height: 82vh;
  overflow-y: auto;
  padding-bottom: 2%;
}
.tracker-model-text{
  margin-top: 1vw;
}
.tracker-heading {
  font-size: 1.4vw;
  color: #c6c6c6;
}
#red-text {
  color: rgba(188, 49, 46, 1);
}
.first-box-div {
  background: rgb(28, 32, 37);
  border-radius: 4px;
  padding: 20px;
  border-radius: 4px;
  height: auto;
  margin-top: 2%;
  font-size: 1.1vw;
}
.latest-model-status>div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:1vw;
  margin-top: 2%;
}
.latest-model-status button {
  border: none;
  font-size: 0.8vw;
  font-weight: bold;
  padding: 0.5vw 1vw;
  border-radius: 0.2vw;
  /* margin-top: 4%; */
}
.model-name-div {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.model-name-div > div:nth-child(1) {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.model-id {
  position: relative;
  width: max-content;
}
.selected-id {
  width: 14vw;
  height: 24px;
  border: 2px solid rgba(153, 193, 227, 1);
  border-radius: 2px;
  padding: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #99c1e3;
  font-size: 0.9vw;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: hidden;
}
.id-lists {
  width: 100%;
  position: absolute;
  top: 120%;
  left: 0;
  border: 2px solid rgba(153, 193, 227, 1);
  border-radius: 2px;
  padding: 10px;
  color: #99c1e3;
  font-size: 0.9vw;
  font-weight: 400;
  background: #1e212d;
  z-index: 2;
  max-height: 20vw;
  overflow-y: auto;
}
.id-lists > div {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  text-align: left;
  overflow-x: hidden;
}
.id-lists > div:hover {
  background-color: #353535;
}
.model-details-box {
  width: 100%;
  /* min-height: 293px; */
  background: #1e212da0;
  border-radius: 4px;
  border-radius: 4px;
  color: #8f8f8f;
  display: flex;
  padding: 2vw;
}
.success-test{
  background-color: #3e9f53;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0.2vw 1vw;
  border-radius: 0.4vw;
}
.error-test{
  background-color: #1c8ca3;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0.2vw 1vw;
  border-radius: 0.4vw;
}
.start-testing-btn{
  background-color: yellow;
  color: black;
}
.model-dtails-div {
  width: 96%;
  /* padding: 20px; */
}
.side-movement-div {
  width: 4%;
  background: #444444;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-span{
  font-style: normal;
  font-weight: 600;
  height: 25px;
  width: 97px;
  padding: 4px 7px;
  background: #2e52bf;
  border-radius: 4px;
  font-size: 1vw;
  line-height: 20px;
  color: #ffffff;
}
.version-id-div {
  margin-top: 16px;
  margin-left: 20px;
}
.version-id-text {
  font-size: 0.9vw;
  font-weight: 400;
  color: rgba(125, 167, 217, 1);
}
.version-id-val {
  font-size: 0.9vw;
  font-weight: 400;
  color: rgba(86, 164, 255, 1);
}
.model-proper-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.model-proper-info > div:nth-child(1),
.model-proper-info > div:nth-child(2) {
  flex: 1;
}
.model-proper-info > div:nth-child(3) {
  flex: 1;
}
.model-proper-info > div > div,.flex-div {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
}
.details-tag-text,.summary-head {
  flex: 0.8;
  text-align: right;
  font-weight: 400;
  font-size: 0.95vw;
  color: #56a4ff;
}
.details-val-text,.summary-content {
  flex: 1;
  text-align: left;
  font-weight: 400;
  font-size: 0.95vw;
  display: flex;
  align-items: center;
}
.modelname-ifo-box {
  font-weight: 600;
  font-size: 1.04vw;
  line-height: 23px;
  color: #e9f8fe;
  margin-left: 20px;
}
.success-count {
  background: #50a33b;
  color: #2c2a2d;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.9vw;
  font-weight: 600;
}
.failure-count {
  background: rgb(184, 26, 26);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.9vw;
  font-weight: 600;
}
.total-count {
  background: rgba(236, 207, 44, 1);
  color: black;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.9vw;
  font-weight: 600;
}
.btn-deactive {
  background: rgb(184, 26, 26);
  border-radius: 70px;
  width: 24px;
  height: 14px;
  padding: 2.7px;
  /* margin-left: 5px; */
  transition-property: background;
  transition-duration: 0.4s;
}
.btn-active {
  background: #3e9f53;
  border-radius: 70px;
  width: 24px;
  height: 14px;
  padding: 2.7px;
  /* margin-left: 5px; */
  transition-property: background;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-draft{
  background: yellow;
  border-radius: 70px;
  width: 24px;
  height: 14px;
  padding: 2.7px;
  /* margin-left: 5px; */
  transition-property: background;
  transition-duration: 0.4s;
}
.toggle-ball-active {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: white;
  margin-left: 50%;
  transition-property: margin-left;
  transition-duration: 0.4s;
}
.toggle-ball-deactive {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: white;
  margin-left: 0px;
  transition-property: margin-left;
  transition-duration: 0.4s;
}
.chat-pop {
  font-size: 30px;
  z-index:2;
}
.comment-box {
  margin-top: 5%;
  margin-bottom: 8%;
}
.comment-div {
  width: 100%;
  padding: 20px;
  background: #1e212da0;
  border-radius: 4px;
  height: 80px;
  border-radius: 4px;
}
.comment-heading {
  color: rgba(86, 164, 255, 1);
  margin-bottom: 2%;
}
.resubmit-btn {
  background: #bc312e;
  border: 1px solid #bc312e;
  border-radius: 2px;
  color: white;
  margin-left: 20px;
  font-size: 0.9vw;
}
.failed-status {
  color: #bc312e;
}
.tag {
  color: #56a4ff;
}
.model-log-btn-div {
  /* margin-top: 5%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  /* margin-bottom: 8%; */
}
/* .model-log-btn-div button{
    background: #2B3140;
    border-radius: 5px;
    color: #8F8F8F;
    padding: 0.9vw 20px;
    width: 150px;
    font-size: 13px;
}
.model-log-btn-div button:hover{
    background-color: #2b3758;
} */
.model-log-btn-div button {
  background-image: linear-gradient(
    to right,
    #314755 0%,
    #005379 51%,
    #314755 100%
  );
  margin: 5px;
  padding: 0.8vw 1vw;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  width: max-content;
  border: none;
  font-size: 0.8vw;
}

.model-log-btn-div button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.change-configure-box {
  width: 100%;
  color: #091440;
  margin-top: 20px;
}
.change-config-input > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.change-config-input > div:nth-child(1) {
  background: #f3f5f6;
  font-size: 16px;
  padding: 10px;
}
.change-config-input > div:nth-child(2) {
  background: white;
  padding-top: 20px;
}
.change-config-input > div > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-config {
  position: relative;
}
.all-configs {
  position: absolute;
  top: 100%;
  left: 0;
}
.change-config-btn-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 20px;
}
.config-edit-btn {
  width: 108.5px;
  height: 44px;
  background: #e6eaed;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border: none;
  color: #8997a5;
}
.config-save-btn {
  width: 108.5px;
  height: 44px;
  background: #3e9f53;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border: none;
  color: white;
}
.modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.79);
}
.model-log-page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-selection-page-overlay{
  position: fixed;
  top: 0;
  left:0;
  height: 100vh;
  width: 100vw;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 12;
}
.image-selction-page-content{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracker-page-without-data{
  display: flex;
  align-items: center;
  margin-top: 5%;
  width:95%;
}
.without-data-img{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.without-data-text{
  flex:1;
  
}
.without-data-img>img{
  width:85% ;
}
.without-data-text-head{
  
  width: 65%;
  margin-left: 35%;
  font-weight: 700;
  font-size: 2.7vw;
  line-height: 3vw;
  /* or 114% */
  text-align: right;
  color: #A4FFFF;
}
.without-data-text-subhead{
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.4vw;
  text-align: right;
  color: #FFFFFF;
  width: 80%;
  margin-left: 20%;
  margin-top: 6%;
}
.without-data-text-button{
  position: relative;
  border-radius: 1vw;
  transition: .5s;
  color: white;
  height: 4vw;
  width: 13vw;
  border-radius: 1vw;
  margin-left: 65%;
  margin-top: 10%;
}
.button-shadow{
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width:100%;
  background:linear-gradient(150deg, rgb(68,188,255), rgb(68,176,255),rgb(255,68,236),rgb(255,68,236));
  z-index: 1;
  filter: blur(25px);
}
.content-btn{
  position: absolute;
  top:0;
  left:0;
  z-index: 3;
  width:100%;
  height: 100%;
  background: #18181B;
  border-radius: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  color: #A4FFFF;
}
.content-btn:hover{
  cursor: pointer;
}
.router-div{
  display: flex;
  font-size: 0.8vw;
  margin-top: 2%;
}

.prev-route{
  color: gold;
}
.prev-route:hover{
  text-decoration: underline;
  cursor: pointer;
}
.router-arrow{
  margin-left: 0.5%;
  display: flex;
  align-items: self-end;
  justify-content: center;
  height: 100%;
  font-size: 1vw;
}
.model-id-route{
  margin-left: 0.5%;
}
.third-box-tracker{
  height: auto;
  width: 100%;
  background-color: rgb(28, 32, 37);
  padding: 1% 0 0 0;
  font-size: 1.1vw;
}
.summary-btn{
  font-style: normal;
  font-weight: 600;
  height: 25px;
  width: 97px;
  background: #2e52bf;
  border-radius: 4px;
  font-size: 1vw;
  line-height: 20px;
  color: #ffffff;
  border:none;
}
.header-third-div{
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  margin-top: 1%;
  background: #444;
  padding: 1%;
}
.header-third-div>div{
  text-align: center;
}
.header-third-div>div:first-child{
  width: 10%;
}
.header-third-div>div:nth-child(2),.header-third-div>div:nth-child(3),.header-third-div>div:nth-child(4){
  width:20%;
}
.header-third-div>div:nth-child(5){
  width: 30%;
}
.content-third-div{
  display: flex;
  align-items: center;
  padding: 1.5%;
  text-align: center;
  font-size: 0.9vw;
    text-align: center;
    background-color: rgb(36, 40, 45);
    margin-top: 2px;
    color: rgba(151, 151, 151, 1)
}

.content-third-div>div:nth-child(1){
  width: 20%;
}
.content-third-div>div:nth-child(2){
  display: flex;
  flex-direction: column;
  gap:2vw;
  width:70%;
  margin-left: 8%;
}
.content-third-div>div:nth-child(2)>div{
  display: flex;
  align-items: center;
  width: 100%;
  
}
.content-third-div>div:nth-child(2)>div>div{
}
.content-third-div>div:nth-child(2)>div>div:nth-child(1),.content-third-div>div:nth-child(2)>div>div:nth-child(2){
  width: 28.57%;
}
.content-third-div>div:nth-child(2)>div>div:nth-child(2){
  width: 42.85%;
}
.input-details{
  width: 28%;
  padding-left: 2%;
}



/*  New Box Designs  */
.heading-div-infobox{
  background-color: rgba(68, 68, 68, 1);
  margin-top: 2%;
}
.heading-div-infobox>div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
}
.heading-div-infobox>div:hover{
  cursor: pointer;
}
.heading-text-infobox{
  width: 7vw;
  background: rgba(46, 82, 191, 1);
  color: white;
  border-radius: 3px;
  padding: 0.2vw ;
  font-size: 0.8vw;
  font-weight: bold;
  text-align: center;
}
.model-summary-box{
  font-size: 1vw;
  width: 100%;
  /* min-height: 293px; */
  background: #1e212da0;
  border-radius: 4px;
  color: #8f8f8f;
  padding: 2vw;
}
.model-summary-box>div{
  width: 100%;
  
  display: flex;
}
.flex-div{
  display: flex;
  align-items: flex-start
}

.summary-content>div,.summary-content>div>div{
  display: flex;
}
.summary-config-head{
  margin-left: 15.8%;
  color: #56a4ff;
}
.summary-config-content{
  width: 76%;
}
.summary-content-config{
  text-align: left;
  font-weight: 400;
  font-size: 0.95vw;
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.summary-config-content>div,.summary-config-content>div>div{
  display:flex ;
  width: 100%;
}


/*   New Changes after Demo   */
.background-infobox{
  background-color: rgb(28, 32, 37);
  padding: 1% 0;
}
.information-box-heading,.information-box-content{
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  text-align: center;
  background-color: rgb(36, 40, 45);
  margin-top: 2px;
}
.information-box-heading>div,
.information-box-content>div{
  flex:1;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.information-box-content{
  color: rgba(151, 151, 151, 1);
}
.green-text{
  color: #3e9f53;
}
.yellow-text{
  color: gold;
}

.red-text{
  color: #c42421;
}
.model-url-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  gap:2vw;
  font-size: 0.9vw;
  margin-top: 1%;
}
.model-url-div>div{
  display: flex;
  align-items: center;
  gap:5px;
  text-decoration: underline;
}
.link-icon{
  font-size: 1vw;
  margin-top: 3%;
  color: rgba(0, 122, 255, 1);
}
.model-description-div{
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  gap:2px;
  text-decoration: none;
}
.model-des-text{
  background-color: rgb(36, 40, 45);
  font-size: 0.7vw;
  max-width: 80%;
  padding: 0.8vw;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.model-details-div{
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
    margin-top: 2%;
    gap:1%;
    padding-left: 4%;
}
.model-details-div>div{
  display: flex;
  align-items: center;
  gap:10px;
  width: fit-content;
}
.model-des-text>div{
  display: flex;
  align-items: center;
}
.details-head{
  color:rgba(217, 217, 217, 1);
}
.config-start-heading{
  font-size: 0.9vw;
  border:1px solid white;
  width: fit-content;
  margin-left: 2.5%;
  padding: 0.3% 1%;
  margin-top: 3%;
  border-radius: 4px;
}
.input-details{
  font-size: 2vw;
  font-weight: bold
}
.input-details:hover{
  cursor: pointer;
}