.model-url{
    position: relative;
    width:100%;
}
.model-url input {
    width: 96%;
    padding:1% 2%;
    font-size: 0.8vw;
    border: none;
    border-radius: 4px;
    min-height: 1.87vw;;
    font-family: inherit;
}

.model-url input:focus {
    outline: none;
}