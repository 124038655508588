.submission-page{
    width: 95%;
    margin: auto;
}
.submit-model-text{
    position: relative;
    font-size: 1vw;
    font-weight: bold;
}
.submit-model-underline{
    position: absolute;
    top:110%;
    height: 0.2vw;
    width: 100%;
    background-color: gold;
}
.sub-btn-div{
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4%
}
.sub-btn-div>button{
    width: 6vw;
    padding: 0.4vw 0.7vw;
    border:none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content:center;
    gap:6%;
    font-size: 1vw;
    font-weight: bold;
}

/* For Pro mode Css */
.proMode-active-div {
    display: flex;
    align-items: flex-start;
    gap: 2%;
    margin-top: 2.5%;
}

.proMode-active-div>div {
    flex: 1;
    min-height: 31vw;
    max-height: fit-content;
}
.proMode-active-div .proMode-right>div{
    background: #1C1B1F;
    min-height: 31vw;
    padding: 0.5vw 0.5vw 1.5vw 0.5vw;
    border-radius: 4px;
}
.proMode-active-div .sec1-div,.proMode-active-div .sec2-div {
    min-height: 31vw;
}
.proMode-active-div .sec1-div>div,.proMode-active-div .sec2-div>div{
    min-height: 31vw;
    padding: 1vw;
}
.proMode-active-div .sec1-div>div>div,.proMode-active-div .sec2-div>div>div{
    width:100%;
}
.sec3-div-left>div{
    width: 100%;
}
.model-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mode-btn{
    width: 8vw;
    padding: 4%;
    background: #1C2025;
    border: 1px solid #938F99;
    border-radius: 4px;
    font-size: 1vw;
    font-weight: bolder;
    display: flex;
    align-items: center;
    gap:10%;
    color:#938F99;
}
.mode-btn img{
    width: 20%;
}
.sec-4-pro-mode{
    margin-top: 7%;
}
.submission-btn-div{
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4%;
    position: relative;
    z-index: 0;
    padding-bottom: 2%;
}
.save{
    background-color: transparent;
    color: #CAC4D0;
    border:1px solid #CAC4D0;
    font-size: 1vw;
    width: 8vw;
    height: 3.2vw;
    border-radius: 0.2vw;
    display: flex;
    gap:1vw;
    align-items: center;
    justify-content: center;
}
.submit{
    background: #1AA053;
    box-shadow: 0px 2px 4px rgba(26, 160, 83, 0.3);
    font-size: 1vw;
    width: 8vw;
    height: 3.2vw;
    border-radius: 0.2vw;
    border: 1px solid #1AA053;
    color: white;
    font-weight: 700;
}
.mode-selection-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:5%;
    width:17vw
}
.expert-mode,.beginner-mode{
    border:1px solid gold;
    color: gold;
}