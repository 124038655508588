@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.App {
  height: max-content;
  text-align: center;
  width: 100%;
  padding: 0px 0px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background-color: black;
}
.early-pop-div{
  position: fixed;
  left:0;
  top:0;
  width: 100vw;
  height:100vh ;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: hidden;
}
.early-pop-overlay{
  background: rgba(0, 0, 0, 0.616);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left:0;
  z-index: 101;
}
.early-pop-form{
  /* height: 50vh;
  width:40vw; */
  z-index: 102;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(170, 170, 170);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(143, 143, 143);
  border-radius: 10px;
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* WebKit */
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button:focus,
input[type="number"]::-webkit-inner-spin-button:focus {
  outline: none;
  -webkit-appearance: none;
  /* Optional: Remove focus outline */
}

.NotApp{
  text-align: center;
    width: 100%;
    padding: 0px 0px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    z-index: -1;;
    background-color: black;
}
#visible-null{
  visibility: hidden;
}
body{
  padding: 0;
  margin:0;
  background-color: black;
}
a{
  text-decoration: none;
}
.pointer-hand:hover{
  cursor: pointer;
}
.laptop{
  display: flex;
}
.mobile{
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.spinner-div{
  position: fixed;
  width:100vw;
  height: 100vh;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}
.spinner-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(70, 70, 70);
  opacity: 0.3;
  z-index: 28;
  
}
.open-alert-dev-overlay{
  position: fixed;
  background: rgba(0, 0, 0, 0.616);
  top:0;
  width:100vw;
  height: 100vh;
  left:0;
  z-index: 70;
  overflow: hidden;
}
.open-alert-dev-content{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  display: flex;
  padding-top: 5%;
  justify-content: center;
  z-index: 71;
}
.pulsate-bck{
  position: fixed;
  left:2%;
  bottom: 10%;

}
.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}
button{
  font-family: inherit;
  cursor: pointer;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-5-2 17:18:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
input[data-autocompleted] {
  background-color: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
/* input:-webkit-autofill, input:-webkit-autofill:focus{
  border: 3px solid blue;
  background-color: transparent !important;
}

input:autofill {
  border: 3px solid blue;
} */
.bounce-in-top {
  
  background-color: black;
  z-index: 10;
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
}
.bounce-in-right {
  position: fixed;
  top: 10%;
  right: 1%;
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-5-11 16:49:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}



.slide-in-blurred-top {
  position: fixed;
  top: 10%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  z-index: 10;
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-20 14:34:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@media only screen and (max-width: 600px) {
  .App {
    overflow-x: hidden;
  }
  .mobile{
    display:flex
  }
  .laptop{
    display: none;
  }
  .back-need {
    padding: 0px 7px;
  }
}
body {
  background-color: black;
  overflow: auto;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cookie-consent-div{
  position: fixed;
  bottom: 0.5vw;
  left:0.5vw;
  width: 400px;
  z-index: 100;
}

@media only screen and (max-width: 600px) {
  .cookie-consent-div {
      position: fixed;
      bottom: 0vw;
      left: 0vw;
      width: 100%;
      z-index: 100;
    }
}