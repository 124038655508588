.profile{
    height: 100vh;
    width:100vw;
    overflow-x: hidden;
}
.nav-profile{
    position: fixed;
    width: 100%;
    top:0;
    left:0;
}
.profile-page-heading{
    padding-top: 13vh;
    font-size: 1.8vw;
}
.profile-page-body{
    width:100%;
    padding: 0% 5%;
    margin: auto;
    background: #080a17;
    min-height: 100vh;
}
.profile-body{
    width:100%;
    margin: auto;
    text-align: left;
    margin-top: 2%;
}
.profile-body>div{
    display: flex;
    align-items:center;
    margin-top: 3%;
}
.profile-body>div>span:nth-child(1){
    width:10vw
}
.token-lists{
    display: flex;
    /* align-items: center; */
    margin-top: 2%;
}
.token-lists>div:nth-child(2)>div{
    display: flex;
    align-items: center;
    gap:10px
}
.side-head{
    font-size: 1vw;
    font-weight: 700;
}
.token-text{
    font-size: 0.8vw;
    
}
.toke-user{
    color: #99C1E3;
    border:2px solid #99C1E3;
    padding: 5px;
}
.clipboard-icon:hover{
    cursor: pointer;
}
.token-clipboard-expire{
    display: flex;
    align-items: center;
}
.expiring-time{
    font-size: 0.8vw;
    color: red;
}
.clipboard-icon{
    margin-top: 0%;
    color:#99C1E3;
    font-size: 1.3vw;
}
.all-tokens{
    margin-left: 2%;
}
.details-user{
    font-size: 0.9vw;
}
.change-password-overlay{
    position: fixed;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.616);
    width:100vw;
    height: 100vh;
    z-index: 5;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.change-password-box{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 8;
}
.profile-options{
    padding-top: 8vw;
    display: flex;
    align-items: center;
    gap:2vw
}
.sub-head-profile{
    position: relative;
    font-size: 1vw;
    font-weight: 900;
}
.sub-head-profile:hover{
    cursor: pointer;
}
.sub-head-profile-underline{
    height: 2px;
    position: absolute;
    top:105%;
    left:0;
    width:100%;
    background-color: gold;
    transition: 2s all width background-color;
}