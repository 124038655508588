.name-change-input{
    background: rgba(230, 234, 237, 0.04);
    /* partition lines */

    border: 1px solid #2D343F;
    border-radius: 4px;
    width:20vw;
    color:white;
    font-size: 0.7vw;
    padding: 0.5vw;
}
.name-change-input:focus{
    outline: none;
}
.edit-store-btns>button{
    font-size: 0.8vw;
    border-radius: 4px;
    width: 8vw;
    padding: 0.5vw;
    border:none
}
.edit-store-btns>button:nth-child(1){
    background-color: white;
    color: black;
}
.edit-store-btns>button:nth-child(2){
    background: #007AFF;
    color: white;
    margin-left: 1%;
}
.bounce-left-div {
    width: auto;
    font-size: 1vw;
    color: rgb(255, 255, 255);
    position: fixed;
    z-index: 5;
    bottom: 5%;
    left: 5%;
}
.bounce-in-left {
    -webkit-animation: bounce-in-left 1.1s both;
    animation: bounce-in-left 1.1s both;
    background: #1f262d;
    padding: 7px 30px;
    border-radius: 5px;

}
.disabled:hover{
    cursor: not-allowed;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-26 15:51:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}