.img-sub-pop-box{
    width:30vw;
    background-color: transparent;
    height: 22vw;
}
.mgn-btn-white-box{
    min-height: 80%;
    margin-top: 20%;
    background-color: white;
    border-radius: 0.4vw;
    position: relative;
    color: black;
    padding: 5%;
}
.closing-icon-pop-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.testing-img{
    margin: auto;
    margin-top: 3%;
    width: 30%;
}
.testing-img>img{
    width: 100%;
}
.testing-in-process{
    margin-top: 3%;
    font-size: 1.1vw;
    font-weight: 800;
    color: rgb(255, 215, 0);
}
.testing-content{
    font-size: 0.7vw;
    color: rgba(102, 112, 133, 1);
}
.testing-page-times{
    font-size: 0.7vw;
    margin-top: 3%;
    color: black;
}