.failure-div {
    width: 30vw;
    background-color: white;
    color: black;
}

.wrong-sign-div {
    height: 7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #981e1e;
}

.wrong-sign-img-div {
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    border: 3px solid white;
    display: flex;
    align-items: center;
    font-size: 2vw;
    color: white;
    justify-content: center;
}

.wrong-sign-img-div img {
    width: 75%
}

.heading-msg {
    color: black;
    margin-top: 6%;
    font-weight: 900;
    font-size: 1.7vw;
}

.failure-msg {
    width: 80%;
    margin: auto;
    font-size: 0.9vw;
    margin-top: 1%;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.605);
}

.register-ok-failure {
    margin-top: 7%;
    margin-bottom: 7%;
    border: none;
    background-color: #981e1e;
    color: white;
    font-size: 1vw;
    padding: 5px 10px;
    width: 40%
}