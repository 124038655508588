/* .change-password-div{
    width:25vw;
    
} */
.change-heading{
    font-size: 1vw;
    color:white;
    font-weight: bolder;
    margin-top: 3%;
    text-align: left;
}
.password-div{
    margin-top: 3%;
    color:white;
    text-align: left;
}
.password-div>div{
    display: flex;
    align-items: center;
    gap:2vw;
    margin-top: 3%;
}
.edit-store-btns{
    display: flex;
    align-items: center;
    margin-top: 5%;
}
.edit-store-btns button{
    font-weight: bold;
}
.password-div>div>div:nth-child(1){
    width:10vw
}
.label-input{
    font-weight: normal;
    font-size: 0.8vw;
    color: white;
    font-weight: bold;
}
/* .password-div input{
    border:1px solid white;
    font-size: 0.7vw;
    padding: 0.3vw;
    width: 100%;
    border-radius: 3px;
} */
.password-div input:focus{
    outline: none;
}
.error-text{
    position: absolute;
    top:103%;
    left:0%;
    color:red;
    font-size: 0.6vw;
    font-weight: bold;
}
.submit-close-btn{
    margin-top: 10%;
    display: flex;
    align-items: center;
    gap:1vw;
    justify-content: center;
}
.submit-close-btn>button:nth-child(1){
    width:6vw;
    font-size: 0.9vw;
    font-weight: bold;
    border:1px solid black;
    padding: 0.2vw 0;
    background-color: transparent;
    border-radius: 5px;
}
.submit-close-btn>button:nth-child(2) {
    width: 6vw;
    font-size: 0.9vw;
    font-weight: bold;
    border: 1px solid gold;
    padding: 0.2vw 0;
    background-color: gold;
    border-radius: 5px;
}
.password-change-success-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.616);
    z-index: 3;
    overflow-y: hidden;
}
.disabled:hover{
    cursor: not-allowed;
}