.section-single-box-not-pro,
.sec-info {
    background: #1C1B1F;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
}

.sec3-div-left,
.sec3-div-right {
    background: #1C1B1F;
    border-radius: 4px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.4vw;
    text-align: left;
    
}
#fix-height{
    min-height: 27.5vw;
}

.sec3-div-right {
    padding-bottom: 2%;
    min-height: 27.5vw;
}

.sec-info-head {
    font-size: 1.07vw;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.cross {
    transform: rotate(135deg);
    transition: transform 0.5s ease;
}

.plus {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

.sec3-div {
    display: flex;
    gap: 5%;
    align-items: flex-start;
}

.sec3-div>div {
    flex: 1
}

/* #sec3{
    margin-top: 10%;
} */
.sec3-1 {
    margin-top: 5.5%;
}

.sec3-2 {
    margin-top: 6%;
}

.sec3-3 {
    margin-top: 6%;
}

.additional-data{
    margin-top: 3%;
}
.sec-info {
    border-bottom: 2px solid rgba(165, 165, 255, 0.15);
    padding-bottom: 0.5vw;
}

.sec-info-relative {
    position: relative;
    width: 100%;
}

.sec-info-desc {
    position: relative;
    max-height: 10vw;
    overflow: auto;
    transition: max-height 0.5s ease;
    font-size: 0.9vw;
    margin-top: 1%;
}
.extra{
    position: relative;
    max-height: 30vw;
    overflow: auto;
    transition: max-height 0.5s ease;
    font-size: 0.9vw;
    margin-top: 1%;
    width: 100%;
    overflow-x: hidden;
}
.sec-info-desc-close {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    font-size: 0.9vw;
    margin-top: 1%;
}

.input-label {
    font-size: 0.85vw;
    line-height: 1.6vw;
}