.image-preview{
    background-color: #1e212d;
    width: 50%;
    height: 90%;
    border-radius: 1vw;
}
.preview-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 1.6vw;
    font-size: 1vw;
}
.image-preview-img-div{
    margin: auto;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-preview-img-div>img{
    width: 95%;
}