.overview-page{
    height: 88vh;
    overflow-y: auto;
    padding-bottom: 3vw;
}
.overview-page-box{
    width:95%;
    background: #1C2025;
    border-radius: 4px;
    height: 20vw;
    margin: auto;
    margin-top: 3%;
    padding: 1% 2%;
}
.overview-heading{
    position: relative;
    width: fit-content;
}
.overview-text{
    font-weight: 700;
    font-size: 1.1vw;
    color: #D9D9D9;
}
.selected-underline{
    position: absolute;
    top:100%;
    left:0;
    width:100%;
    height: 0.2vw;
    background: #FFD700;
}
.hr-line-overview{
    border: 1px solid #2D343F;
    width:100%;
    margin-top: 2%;
}
.overview-box-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 2%; */
    height: 75%;
    text-align: left;
}
.left-bar{
    width:5px;
    height: 80%;
    background: #D9D9D9;
    border-radius: 0px 25px 25px 0px;
}
.overview-single-box{
    flex:1;
    border-left:1px solid #2D343F;
    height: 100%;
    display: flex;
    align-items: center;
    gap:1.3vw
}
.overview-single-box:hover{
    cursor: pointer;
}
.overview-single-box:hover .left-bar{
    background: gold;
    
}
.overview-single-box:hover .overview-single-box-text{
    color: gold;
}
.overview-single-box-text{
    position: relative;
    width: max-content;
    font-size: 1.1vw;
}
.overview-single-box-values{
    font-size: 1vw;
}
.overview-single-box:hover .underlined-bar{
    background-color:gold
}
.underlined-bar{
    position: absolute;
    top:100%;
    left: 0;
    width: 100%;
    height: 0.2vw;
    background-color: #D9D9D9;
}
.box-texts{
    height: 70%;
}
.overview-single-box-values{
    margin-top: 4vw;
}
.assignedPop-modal{
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    left:0;
    z-index: 4;
}
.overlay-assignedImg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background: black;
}


/* without-access-portal */

.without-access{
    width:95%;
    margin: auto;
    margin-top: 5%;
}
.without-access-heading{
    display: flex;
}
.without-access-heading-text{
    width:50%;
    padding-top: 5%;
    padding-right: 10%;
    font-size: 2vw;
    line-height: 2.5vw;
    text-align: left;
    font-weight: 700;
    color: #A4FFFF;
}
.without-access-head-img>img{
    width:40vw
}
.widthout-access-description{
    margin-top: 5%;
    width: 80%;
}
.widthout-access-description>div{
    display: flex;
    align-items: center;
    gap:20px;
    text-align: left;
    margin-top: 2%;
    font-size: 1vw;
}
.tick-icon{
    font-size: 1.4vw;
    color: gold;
    margin-top: -0.4%;
}
.checking-email-in-overview{
    background: #303940;
    /* Dark Gray / Dark Gray 4 */

    border: 1px solid #3C464E;
    /* Shadow / Elevation 6 */

    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24);
    border-radius: 6px;
    font-size: 1vw;
    margin-top: 4%;
    padding: 2%;
    text-align: left;
    margin-bottom: 5%;
}
.click-here-btn-for-email{
    color: #A4FFFF;
    padding-right: 5px;
    text-decoration: underline;
}
.click-here-btn-for-email:hover{
    cursor: pointer;
}