.alert-after-emial-verification{
    width:30vw;
    height: 24vw;
    z-index: 8;
    background-color: white;
    border-radius: 5px;
    padding: 2vw 0;
}
.alert-verify-box{
}
.mail-icon-div{
    width:5vw;
    height: 5vw;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D1FADF;
    border: 8px solid #ECFDF3;
}
.mail-icon{
    color: rgba(3, 152, 85, 1);
    font-size: 2.5vw;
}
.email-verify-head{
    color: black;
    font-size: 1.4vw;
    font-weight: 800;
    margin-top: 5%;
}
.sent-email-text{
    color: rgba(0, 0, 0, 0.584);
    font-weight: 600;
    font-size: 0.8vw;
    width:90%;
    margin: auto;
    margin-top: 3%;
}
.resent-email-btn-div{
    margin-top: 9%;
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: center;
}
.resent-email-btn-div button:nth-child(2){
    background-color: yellow;
    color: black;
    border: 1px solid yellow;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 5px;
    width:8vw;
    padding: 0.6vw 0;
}
.resent-email-btn-div button:nth-child(1) {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 5px;
    width: 8vw;
    padding: 0.6vw 0;
}