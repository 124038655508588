.output-box {
    background-color: #1e212d;
    /* padding: 20px; */
    width: 70vw;
    max-height: 88vh;
    border-radius: 4px;
    overflow-y: hidden;
    position: relative;
    /* overflow: hidden; */
}

.output-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4%;
    position: absolute;
    top: 3%;
    left: 0%;
    width: 96%;
    padding: 0px 2%;
}

.output-content {
    margin-top: 12%;
    height: 30vw;
    overflow-y: auto;
    padding: 1% 2%;
}

.table-head {
    position: absolute;
    top: 13%;
    width: 96%;
    left: 0;
    padding: 1% 2%;
    z-index: 2;
    background: var(--dark, #1C1B1F);
}

.output-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 38vw;
    font-size: 0.8vw;
    border-bottom: 1px solid rgb(65, 71, 91);
}

.output-table>div {
    text-align: center;
    /* border:1px solid red */
}

.content-height {
    min-height: 15vw;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.8vw;
}

.content-height>div:nth-child(1) {
    width: 5%;
    /* border: 1px solid red; */
}

.content-height>div:nth-child(2) {
    width: 17.5%;
    /* border: 1px solid red; */
}

.content-height>div:nth-child(3) {
    width: 17.5%;
    /* border: 1px solid red; */
}

.content-height>div:nth-child(4) {
    width: 60%;
    /* border: 1px solid red; */
}

.content-height>div {
    min-height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px
}

.content-height>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border:1px solid red; */
    width: 100%;
    text-align: center;
}

.content-height>div>div>div:nth-child(1) {
    /* border: 1px solid red; */
    flex: 1.79
}

.content-height>div>div>div:nth-child(2) {
    /* border: 1px solid red; */
    flex: 2.2
}

.content-height>div>div>div:nth-child(3) {
    /* border: 1px solid red; */
    flex: 1
}

.output-table>div:nth-child(1) {
    flex: 0.6
}

.output-table>div:nth-child(2) {
    flex: 2
}

.output-table>div:nth-child(3) {
    flex: 2
}

.output-table>div:nth-child(4) {
    flex: 2.5
}

.output-table>div:nth-child(5) {
    flex: 3
}

.output-table>div:nth-child(6) {
    flex: 1.5
}

/* .output-table>div:nth-child(1),.output-table>div:nth-child(3),.output-table>div:nth-child(4){
flex:1.2;
text-align: center;
margin-top: 2%;
}
.output-table>div:nth-child(2){
flex: 2;
text-align: center;
margin-top: 2%;
}
.output-table>div:nth-child(5){
flex: 4;
text-align: center;
margin-top: 2%;
} */
.object-shown-area {
    min-height: 10vw;
    max-height: 20vw;
    overflow-y: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closing-cross-icon:hover {
    cursor: pointer;
}

.input-id-masking {
    position: relative;
}

.input-id {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15%
}

.open-masks {
    position: absolute;
    min-height: 5vw;
    top: 120%;
    left: 0;
    z-index: 3;
    width: 100%;
    background-color: #36373e;
    color: white;
}

.close-masks {
    position: absolute;
    height: 0vw;
    top: 100%;
    left: 0;
    z-index: -3;
}

.cloud-links {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0%;
    justify-content: center;
    flex-direction: column;
}

.cloud-links>a {
    text-decoration: underline;
}

.mask-download-buttons {
    border: 1px solid rgb(173, 173, 173);
    width: 70%;
    padding: 0.2vw 0;
    font-size: 0.6vw;
    border-radius: 0.2vw;
    color: white;
}

.mask-download-buttons:hover {
    cursor: pointer;
}

.preview-image-download-btn {
    border: 1px solid rgb(173, 173, 173);
    width: fit-content;
    padding: 0.2vw 2vw;
    font-size: 0.6vw;
    border-radius: 0.2vw;
}

.overlay-background {
    background-color: rgba(0, 0, 0, 0.795);
    height: 88vh;
    width: 70vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.output-img-preview {
    height: 88vh;
    width: 70vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats-table {
    width: 70%;
    margin: auto;
    border: 1px solid rgb(173, 173, 173);
    font-size: 0.8vw;
    margin-top: 2%;
    margin-bottom: 2%;
}

.stats-table>div {
    display: flex;
    align-items: center;
}

.stats-table>div:nth-child(1) {
    background-color: #1C1B1F;
    padding: 1% 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats-table>div:nth-child(2),
.stats-table>div:nth-child(3) {
    padding: 1% 2%;
    display: flex;
    align-items: center;
    color: rgb(173, 173, 173);

}

.stats-table>div:nth-child(1)>div,
.stats-table>div:nth-child(2)>div,
.stats-table>div:nth-child(3)>div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.execution-details {
    font-size: 0.8vw;
    color: rgb(173, 173, 173);
    border: 1px solid rgb(173, 173, 173);
    width: 68%;
    margin: auto;
    margin-top: 3%;
    /* display: grid; */
    /* grid-template-columns: repeat(2,1fr); */
    padding: 1%;
}

.execution-key {
    font-weight: bold;
    color: gold;
    line-height: 2vw;
}

.output-table-heading {
    background: var(--dark, #333334);
    display: flex;
    align-items: center;
    justify-content: center;
}

.output-table-heading>div {
    flex: 1;
    padding: 0.7vw 0 0.7vw 0.8vw;
    color: gold;
    font-weight: bold;
    text-align: center;
}

.output-table-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.output-table-content>div {
    flex: 1;
}

.output-table-content>div:nth-child(1),
.output-table-content>div:nth-child(2) {

    border-right: 1px dotted rgba(255, 255, 255, 0.251);
}

.output-table-content>div>div {
    padding: 0.7vw 0.8vw;
    margin-top: 1%;
    background: var(--dark, #1f1f22);
    text-align: center;
}

.output-links {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0%;
    justify-content: center;
    flex-direction: column;
    color: white;
    width: auto;
}

.output-links>a {
    text-decoration: underline;
}

.output-download-buttons {
    border: 1px solid rgb(173, 173, 173);
    width: 100%;
    padding: 0.2vw 0.2vw;
    font-size: 0.6vw;
    border-radius: 0.2vw;
    color: white;
}

.output-download-buttons:hover {
    cursor: pointer;
}

.visualize-result-button {
    border: 1px solid rgb(173, 173, 173);
    width: 100%;
    padding: 0.2vw 0.2vw;
    font-size: 0.6vw;
    border-radius: 0.2vw;
    color: white;
    text-decoration: none;
}

.visualize-result-button:hover {
    cursor: pointer;
}