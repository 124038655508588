.not-access-div{
    position: relative;
    padding: 1vw;
    font-size: 0.8vw;
    color:white
}
.pointer-div{
    width:1vw;
    height: 1vw;
    position: absolute;
    top:-0.5vw;
    left:10%;
    background: #F64B3C;
    transform: rotate(45deg);
}