.portal-navbar-page{
  height: 100%;
}

.navbar-page {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2vw;
  background-color: black;
  height: 100%;
  z-index: 2;
  margin: 0;
}
.logo-img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & img {
    width: 60%;
    margin-left: -15%;
  }
}
.navbar-routes {
  flex: 4.6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: #d9d9d9;
  }
}
.navbar-routes > div {
  display: flex;
  // align-items: flex-end;
  justify-content: space-between;
  gap: 5vw;
  padding: 0px 20px;
  color: #d9d9d9;
  font-weight: 300;
}
.navbar-routes > div:first-child {
  margin-top: 1vw;
}
.active-portal {
  font-weight: 600;
  font-size: 1.3vw;
  position: relative;
}
.active-underline {
  position: absolute;
  background: #ffd700;
  height: 0.2vw;
  width: 100%;
  top: 100%;
  left: 0;
}
.basic-menu {
  font-size: 1.3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.email-verification-btn{
  background-color: #ffd700;
  font-size: 1vw;
  font-weight: bold;
  border:none;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  box-shadow: inset 0 0 0.2em 0 gold, inset 0 0 0.2em 0 gold;
}