.toastifying{
    color:#3e9f53;
    background-color: white;
    padding: 0.7vw 3vw 0.7vw 1vw;
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: 4px solid #3e9f53;
    font-size: 1vw;
    gap:2vw;
    font-weight: bold;
}
.done-icon{
    font-size: 2vw;
}