.visualize-result {
    background-color: #1e212d;
    width: 80%;
    height: 70%;
    border-radius: 1vw;
  }
  
  .visualize-result-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6vw;
    font-size: 1.5vw;
    font-weight: 700;
  }
  
  .visualize-result-head-txt {
    padding-top: 10px;
    margin: auto;
  }

  .visualize-result-img-div {
    margin: auto;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .visualize-result-img-div .image-container {
    position: relative;
    width: 45%;
    text-align: center;
  }
  
  .visualize-result-img-div .image-container img {
    width: 100%;
  }
  
  .image-label {
    position: relative;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    /* padding: 2px 10px; */
    border-radius: 5px;
    font-size: 1vw;
  }
  
  .arrow-container {
    width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px;
  }
  
  .arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .arrow-text {
    margin-bottom: 10px;
    font-size: 1vw;
    font-weight: 700;
    color: white;
  }
  
  .arrow-symbol {
    font-size: 4vw;
    color: white;
  }
  