.not-found-page{
    background: linear-gradient(45deg, #000000, #3c3c3c);
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.not-found-div{
    height: 30vw;
    width:50vw;
    border-radius: 1vw;
    box-shadow: inset 0.1vw 0.1vw 1.3vw rgb(75, 75, 75);
}
.number-text{
    font-size: 5vw;
    font-weight: bold;
    margin-top: 5%;
    letter-spacing: 0.6vw;
}
.not-found-text{
    background-color: white;
    color: black;
    font-size: 1vw;
    font-weight: bold;
    width: 30%;
    margin: auto;
    padding: 1vw 0vw;
    border-radius: 0.4vw;
    margin-bottom: 6%;
}
.not-found-description{
    margin-top: 2vw;
    width: 60%;
    margin: auto;
    font-size: 0.9vw;
}
.back-to-home-btn{
    margin-top: 5%;
    font-size: 1vw;
}
.back-to-home-btn button{
    background-color: gold;
    color: black;
    padding: 0.7vw 2vw;
    border-radius: 10vw;
    border: none;
    font-weight: bold;
}