.dev-home {
  color: white;
  background: #080a17;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.dev-home-navbar {
  height: 8vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.dev-home-body {
  display: flex;
  height: 100vh;
  padding-top: 0%;
}
.dev-home-body > div:nth-child(1) {
  flex: 1.1;
  /* background-color: rgb(196, 196, 196); */
  color: white;
  height: 92vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.dev-home-body > div:nth-child(2) {
  flex: 5;
  padding: 1%;
  height: 87.4vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.dev-home-body-contents-div{
  height: 100%;
  /* overflow-y: auto; */
}
.dev-home-body-mob {
  display: none;
}
.tooltip-head{
  font-size: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-weight: bold;
}
.closing-icon{
  margin-top: -12%;
}
.closing-icon:hover{
  cursor: pointer;
}
.tooltip-details{
  color: #667085;
  font-size: 1vw;
  margin-top: 5%;
  line-height: 1.7vw;
}
.progress-points{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:2%;
  margin-top: 10%;
}
.dots{
  width: 0.5vw;
  height: 0.5vw;
  background-color: #c5c5c5;
}
.active-dot {
  width: 0.5vw;
  height: 0.5vw;
  background: #007AFF;
}
.tooltip-btns{
  display: flex;
  gap:2%;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.tooltip-btns > button:nth-child(1){
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #929292;
  border-radius: 5px;
  font-size: 1vw;
  padding: 2% 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5%
}
.tooltip-btns>button:nth-child(2) {
  background: #007AFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  color: white;
  font-size: 1vw;
  padding: 2% 5%;
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%
}
.next-btn{
  background-color: aqua;
}

.tour-btn{
  position: fixed;
  left:2%;
  bottom: 10%;
  width:8.5vw;
  background: #080A17;
  border: 1px solid #938F99;
  border-radius: 4px;
  font-size: 1vw;
  color:#938F99 ;
  display: flex;
  align-items: center;
  gap:5%;
  padding: 0.7%;
  font-weight: bold;
}
.tour-btn img{
  width:20%
}
.mode-selection-btn{
  position: fixed;
  top:10%;
  right:4%
}
.tooltip-tour-img-div{
  top:10px;
  width: 25%;
  position: relative;
  margin: auto;
}
.tooltip-tour-img-div>img{
  width:100%
}
.tooltip-tour-text{
  position: absolute;
  top:15%;
  left:8%;
  font-size: 0.6vw;
  color: white;
}
.anchor-tag{
  color: rgb(181, 153, 0);
  text-decoration: underline;
  margin-left: 2%;
}
.anchor-tag:hover{
  color: rgb(181, 153, 0);

}
.mobile-device-error{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}