.model-desc{
    position: relative;
    width: 100%;
}
.error-div{
    position: absolute;
    top:108%;
    left:0;
    color: white;
    font-size: 0.7vw;
    /* font-weight: bold; */
    max-width: 100%;
    
}
.error-div>div{
    background: rgba(213, 0, 0, 1);
    position: relative;
    /* border: 1px solid white; */
    color: white;
    display: flex;
    align-items: center;
    border-radius: 0.2vw;
    line-height: 1.2vw;
}
.error-div>div>div:nth-child(2){
    height: 100%;
    width: 100%;
    z-index: 3;
    padding: 0.3vw;
    background-color:rgba(213, 0, 0, 1) ;
    border-radius: 0.2vw;
}
.pointer-error{
    position: absolute;
    height: 0.7vw;
    width: 0.7vw;
    background-color: rgba(213, 0, 0, 1);
    transform: rotate(45deg);
    top: -10%;
    left: 15px;
    /* border:1px solid white; */
    z-index: 0;
}
.text-area-div{
    height: 6.5vw;
    
}
.model-desc input{
    
    border: 1px solid red
}
.model-desc input:focus{
    outline: none;
}


/* latest css  */

.model-desc {
    position: relative;
    width: 100%;
}

.model-desc textarea {
    resize: none;
    width: 96%;
    padding:1% 2% 0 2%;
    font-size: 0.8vw;
    border: none;
    border-radius: 4px;
    height: 5.7vw;
    font-family: inherit;
}

.model-desc textarea:focus {
    outline: none;
}

.color-white {
    color: white;
}