.monitor-table {
  width: 95%;
  margin: auto;
  max-height: 72vh;
  overflow: auto;
  border-radius: 4px;
  background: #1c2025;
  color: white;
  padding: 20px;
  font-size: 0.85vw;
  margin-top: 3%;
}
.monitor-head {
  color: #c6c6c6;
  font-size: 1.4vw;
}
.static-head {
  background: #34333a;

  color: white;
  font-weight: 700;
  border-radius: 4px;
  margin-top: 2%;
}
.static-head > div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
}
.table-content button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  text-decoration: underline;
}
.resubmission-monitor {
  color: rgba(221, 111, 111, 1);
}
.static-head,
.table-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 20px;
}
.expanded-content {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  gap: 20px;
  background: transparent;
  text-align: left;
}
.expanded-content > div:nth-child(3),
.expanded-content > div:nth-child(7) {
  flex: 1;
  text-align: center;
}
.expanded-content > div:nth-child(1) {
  flex: 1.5;
  text-align: center;
}
.expanded-content>div:nth-child(1)>div:nth-child(1){
  flex:2;
}
.expanded-content > div:nth-child(2),
.expanded-content > div:nth-child(4),
.expanded-content > div:nth-child(5),
.expanded-content > div:nth-child(6) {
  flex: 0.8;
  text-align: center;
}
.monitor-table > div > div > div:nth-child(3),
.monitor-table > div > div > div:nth-child(7) {
  flex: 1;
  text-align: center;
}
.monitor-table > div > div > div:nth-child(1) {
  flex: 1.5;
  text-align: center;
}
.monitor-table > div > div > div:nth-child(2),
.monitor-table > div > div > div:nth-child(4),
.monitor-table > div > div > div:nth-child(5),
.monitor-table > div > div > div:nth-child(6) {
  flex: 0.8;
  text-align: center;
}
.model-name-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.model-name-box > div:nth-child(1) {
  flex: 1;
}
.model-name-box > div:nth-child(2) {
  flex: 10;
  text-align: left;
}
.model-name-box:hover{
  cursor: pointer;
}
.arrow-div {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: start;
  color: rgba(122, 122, 125, 1);
}
.red {
  background: rgba(221, 111, 111, 1);
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 8px 12px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.green {
  background: rgba(62, 159, 83, 1);
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 8px 12px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.yellow {
  background: #eccf2c;
  color: rgb(0, 0, 0);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.sky {
  background: skyblue;
  color: rgb(0, 0, 0);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.resubmission-monitor {
  color: rgba(168, 48, 24, 1);
  font-size: 0.8vw;
}
.acivated-model {
  color: rgba(54, 179, 104, 1);
}
.inactivated-model,
.access-control-button {
  color: inherit;
}
.new-modal {
  position: absolute;
  width: 450px;
  height: 300px;
  z-index: 10;
  background-color: white;
  border-radius: 10px;
  /* transform: translate(50%, 50%); */
  left: 40%;
  top: 30%;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.616);
  z-index: 3;
}
.collapseable-main {
  overflow: auto;
  background: #34333a;
  margin: auto;
  border-radius: 4px;
  padding: 0px 10px;
}
.expandable-div {
  max-height: 200px;
  visibility: visible;
  transition-property: height, visibility;
  transition-duration: 0.5s;
}
.collapseable-div {
  height: 0;
  visibility: hidden;
  transition-property: height, visibility;
  transition-duration: 0.5s;
}
.fliter-div {
  width: 13%;
  margin-left: 87%;
  position: relative;
}
.filter-1-text,
.filtering-data {
  padding: 10px;
}
.filter-1-text:hover,
.selected-sub-filter,
.filtering-data:hover {
  background: rgba(241, 244, 249, 1);
}
.filter-1 {
  position: relative;
}
.sub-filter-list {
  position: absolute;
  width: 100%;
  right: 100%;
  top: 0%;
  height: auto;
  background-color: white;
  border: 1px solid #e6eaed;
}
.filter-submission-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.filter-submission-btn button {
  background: #2970ff;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 7px 10px;
}
.filter-selection-div {
  position: absolute;
  width: 100%;
  top: 120%;
  left: 0;
  height: auto;
  background-color: white;
  border: 1px solid #e6eaed;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.selcted-filter-div {
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 100%;
  border: 1px solid #e6eaed;
  border-radius: 4px;
}
.selected-filter-text {
  width: 170px;
}
.tracker-page-without-data-monitor {
  display: flex;
  align-items: center;
  margin-top: 5%;
  width: 100%;
  margin-left: 0%;
  padding-left: 2%;
}

.without-data-img-monitor {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.without-data-text-monitor {
  flex: 1;

}

.without-data-img-monitor>img {
  width: 85%;
}
.without-data-text-head-monitor {
  width: 65%;
  margin-left: 0%;
  font-weight: 700;
  font-size: 2vw;
  line-height: 3vw;
  /* or 114% */
  text-align: left;
  color: #A4FFFF;
}

.without-data-text-subhead-monitor {
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.4vw;
  text-align: left;
  color: #FFFFFF;
  width: 80%;
  margin-left: 0%;
  margin-top: 6%;
}

.without-data-text-button-monitor {
  position: relative;
  border-radius: 1vw;
  transition: .5s;
  color: white;
  height: 4vw;
  width: 17vw;
  border-radius: 1vw;
  margin-left: 0%;
  margin-top: 10%;
}

.button-shadow-monitor {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(150deg, rgb(68, 188, 255), rgb(68, 176, 255), rgb(255, 68, 236), rgb(255, 68, 236));
  z-index: 1;
  filter: blur(25px);
}

.content-btn-monitor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #18181B;
  border-radius: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  color: #A4FFFF;
}

.content-btn-monitor:hover {
  cursor: pointer;
}
/* #model-status:hover{
  cursor: pointer;
} */
.view-button{
  text-decoration: underline;
  color: gold;
  padding: 1% 0;
}
.view-button:hover{
  cursor: pointer;
}