.auth-key{
    position: relative;
    width:100%;
}
.auth-key input{
    width: 96%;
    padding:1% 2%;
    font-size: 0.8vw;
    border: none;
    border-radius: 4px;
    min-height: 1.87vw;;
    font-family: inherit;
}
.auth-key input:focus{
    outline: none;
}
.eye-icon{
    margin-right: 1.5%;
    font-size: 1.3vw;
}