.sec4-div {
    display: flex;
    gap: 5%;
    align-items: flex-start;
}

.sec4-div>div {
    flex: 1
}
.section-single-box{
    width: 100%;
}
.sec4-div-left,.sec4-div-right{
    background: #1C1B1F;
    border-radius: 4px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.4vw;
    text-align: left;
}