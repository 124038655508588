.assignedImg-pop{
    background: #1f262d;
    padding: 10px;
    border-radius: 5px;
}
.heading-assignedimg{
    font-size: 1.1vw;
}
.image-url-div{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
}
.image-box-to-be-selected{
    position: relative;
    height: 100px;
    width: 100px
}
.img-overlay{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.616);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}
.preview-img-div{
    max-height: 27vw;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:10px;
    width: fit-content;
    margin-top: 3%;
    padding: 0px 5px 0px 0px;
}
.preview-img-div img{
    height: 100%;
    width:100%
}
.preview-img-div button{
    font-size: 0.9vw;
    background-color: transparent;
    border:none;
    display: flex;
    align-items: center;
}
.image-url-div {
    background-color: rgb(220, 220, 220);
    padding: 10px;
    border-radius: 10px;
}
.clipboard-icon{
    font-size: 1vw;
    margin-top: -5%;
    margin-left: 10px;
}

.bounce-left-div{
    width:auto;
    font-size: 1vw;
    color: rgb(255, 255, 255);
    position: fixed;
    z-index: 5;
    bottom: 5%;
    left:10%;
}
.copy-selected-btn{
    background-color: transparent;
    border: 1px solid white;
    font-size: 0.9vw;
    padding: 6px 30px;
    border-radius: 3px;
    margin-top: 5%;
    color: white;
}

.bounce-in-left {
    -webkit-animation: bounce-in-left 1.1s both;
    animation: bounce-in-left 1.1s both;
    background: #1f262d;
    padding: 7px 30px;
    border-radius: 5px;

}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-26 15:51:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}