.image-metadata-div{
    position: fixed;
    top:0;
    left:0;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-metadata-box{
    height: 35vw;
    width: 60vw;
    background: rgb(31, 38, 45);
    border-radius: 1vw;
    padding:1vw 1vw;
    gap:1vw;
    justify-content: space-between;
}
.metadata-img-div{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:1vw;
    align-items: flex-start;
}
.metadata-img-div img{
    width: 100%;
}
.metadata-heading{
    font-size: 1.3vw;
    font-weight: bold;
    color: gold;
}
.metadata-text-div{
    flex:2;
    height: 100%;
}
.metadata-id{
    font-size: 0.8vw;
}
.metadata-id-text{
    color:rgb(135, 119, 203)
}
.metadata-text-div main{
    width: 40vw;
    overflow: auto;
    height: 100%;
    padding: 1vw 0;
}
.close-btn-image-metadata{
    display: flex;
    align-items: end;
    justify-content: flex-end;
}