.model-visibility-selection-div{
    position: relative;
}
.model-visibility{
    width: 100%;
}
.model-visibility-selected{
    width: 96%;
    display: flex;
    padding:1% 2%;
    border-radius: 4px;
    background-color: white;
    color: black;
    justify-content: space-between;
    font-size: 0.8vw;
    min-height: 1.87vw;;
}
.model-visibility-selected>div{
    display: flex;
    align-items: center;
}
.model-visibility-selected:hover{
    cursor: pointer;
}
.disable:hover{
    cursor: not-allowed;
}