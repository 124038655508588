.chatpage {
  position: relative;
}
.chatpage .chat-body-open {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 29.6vw;
  top: 100%;
  right: -20%;
  background: #101017;
  opacity: 1;
  border-radius: 10px;
  transition-property: width, height, border, padding, opacity;
  transition-duration: 0.5s;
}
.chatpage .chat-body-open .chatting-box-div {
  display: flex;
  align-items: center;
  height: 5%;
  justify-content: center;
  background: #ffffff;
  box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
  margin-top: 0px;
  font-size: 0.9vw;
  width: 90%;
  margin: auto;
  padding: 0.8vw 0;
}
.chatpage .chat-body-open .chatting-box-div button {
  font-size: 1.2vw;
  border: none;
  background-color: transparent;
  color: #3369ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatpage .chat-body-open .chatting-box-div .input-box-chat {
  width: 90%;
  font-size: 0.8vw;
  border: none;
}
.chatpage .chat-body-open .chatting-box-div .input-box-chat:focus {
  outline: none;
}
.chatpage .chat-body-open .chatting-box-div form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}
.chatpage .chat-body-open .chat-body-box-content {
  width: 100%;
  padding: 1% 0;
  overflow-y: auto;
  min-height: 60%;
  max-height: 60%;
  background-color: #12121d;
}
.chatpage .chat-body-open .chat-body-box-content h2:hover {
  cursor: pointer;
}
.chatpage .chat-body-open .chat-logo {
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.chatpage .chat-body-open .chat-logo div {
  width: 2.7vw;
  height: 2.7vw;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: width, height;
  transition-delay: 0.2s;
  margin-right: 10px;
}
.chatpage .chat-body-open .chat-logo img {
  width: 1.8vw;
}
.chatpage .chat-body-close {
  position: absolute;
  display: none;
  width: 0px;
  height: 0px;
  top: 100%;
  right: 0%;
  border-radius: 10px;
  background: #444444;
  opacity: 0;
  transition-property: width, height, border, padding, opacity;
  transition-duration: 0.5s;
}
.chatpage .chat-body-close .chatting-box-div {
  display: flex;
  align-items: center;
  height: 10%;
  justify-content: center;
  width: 90%;
  margin: auto;
  background: #ffffff;
  box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
  margin-top: 10px;
  padding: 0px 18px 0px 18px;
}
.chatpage .chat-body-close .chatting-box-div button {
  font-size: 1.2vw;
  border: none;
  background-color: transparent;
  color: #3369ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatpage .chat-body-close .input-box-chat {
  width: 90%;
  font-size: 0.8vw;
  border: none;
}
.chatpage .chat-body-close .input-box-chat:focus {
  outline: none;
}
.chatpage .chat-body-close .chat-body-box-content {
  width: 100%;
  margin: auto;
  height: 72%;
  overflow-y: hidden;
  padding: 0px;
  transition-property: padding;
  transition-delay: 0.2s;
}
.chatpage .chat-body-close .chat-body-box-content h2:hover {
  cursor: pointer;
}
.chatpage .chat-logo {
  height: 10%;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  width: 90%;
  margin: auto;
  transition-property: padding;
  transition-duration: 0.4;
}
.chatpage .chat-logo div {
  width: 0px;
  height: 0px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: height, width;
  transition-delay: 0.3s;
}
.chatpage .chat-logo img {
  width: 0px;
}

.user {
  font-size: 0.8vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.user div {
  max-width: 70%;
  margin-top: 10px;
  padding: 5px;
  padding: 10px;
  background: #2b3140;
  border-radius: 25px 0px 25px 25px;
  color: white;
}

.admin {
  font-size: 0.8vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.admin div {
  max-width: 70%;
  margin-top: 10px;
  padding: 5px;
  color: black;
  background: #eeeeee;
  padding: 10px;
  border-radius: 25px 25px 25px 0px;
}

.chat-icon {
  position: relative;
  font-size: 2vw;
}

.chat-icon:hover {
  cursor: pointer;
}

.reverse-chat {
  position: absolute;
  top: 15%;
  left: 30%;
  transform: scaleX(-1);
  z-index: 7;
  color: #36579e;
  font-size: 1.7vw;
}

.scrollable {
  padding: 1vw;
  background-color: #12121d;
  height: auto;
  overflow: auto;
}/*# sourceMappingURL=ChatPage.css.map */