.early-access-form{
    width:30vw;
    height: auto;
    padding: 20px;
    text-align: left;
    background: #1E1E1E;
}
.label{
    font-size: 1vw;
    font-weight: 600;
    margin-bottom: 2%;
}
.add-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-details-text{
    font-weight: 700;
    font-size: 2vw;
}
.add-details-sub-heading{
    font-size: 0.8vw;
    margin-bottom: 5%;
}
.input-box{
    width: 100%;
    padding: 10px;
    background: #262626;
    border: 1px solid #666666;
    border-radius: 6px;
    margin-bottom: 8%;
    color:white
}
.formik-submit{
    background: #FFD700;
    border-radius: 4px;
    border:none;
    padding: 10px 0px;
    font-size: 1vw;
    font-weight: 600;
    margin-left: 35%;
    width:30%;
    color: black;
}

.submission-done{
    width: 55vw;
    height: auto;
    padding: 30px;
    text-align: left;
    background: #1E1E1E;
}
.submission-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.submission-head>div:nth-child(1){
    font-size: 2vw;
    font-weight: 700;
}
.submission-head button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.6vw;
}
.submission-sub-head{
    margin-top: 5%;
    font-size: 1.6vw;
    color: #CAC4D0;
}
.submission-description{
    width:85%;
    font-size: 0.7vw;
    color: #CAC4D0;
    margin-top: 5%;
}
@media only screen and (max-width: 600px){
    .early-access-form {
        width: 95vw;
        height: auto;
        padding: 20px;
        text-align: left;
        background: #1E1E1E;
    }

    .label {
        font-size: 2.4vw;
        font-weight: 600;
        margin-bottom: 2%;
    }

    .add-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .add-details-text {
        font-weight: 700;
        font-size: 5vw;
    }

    .add-details-sub-heading {
        font-size: 2vw;
        margin-bottom: 5%;
    }

    .input-box {
        width: 100%;
        padding: 10px;
        background: #262626;
        border: 1px solid #666666;
        border-radius: 6px;
        margin-bottom: 5%;
        color: white
    }

    .formik-submit {
        background: #FFD700;
        border-radius: 4px;
        border: none;
        padding: 10px 0px;
        font-size: 2.4vw;
        font-weight: 600;
        margin-left: 35%;
        width: 30%;
        color: black;
    }

    .submission-done {
        width: 95vw;
        height: auto;
        padding: 20px;
        text-align: left;
        background: #1E1E1E;
    }

    .submission-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .submission-head>div:nth-child(1) {
        font-size: 5vw;
        font-weight: 700;
    }

    .submission-head button {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 4vw;
    }

    .submission-sub-head {
        margin-top: 5%;
        font-size: 3vw;
        color: #CAC4D0;
    }

    .submission-description {
        width: 85%;
        font-size: 2vw;
        color: #CAC4D0;
        margin-top: 5%;
    }
}