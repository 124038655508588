.login-password-check{
    width: 30vw;
    background: #1f262d;
    padding: 10px;
    border-radius: 5px;
}
.login-password-check-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
}
.login-password-check-heading>div:nth-child(1){
    font-size: 1.2vw;
    font-weight: 800;
}
.login-password-check-body{
    display: flex;
    align-items:center;
    gap:20px;
    font-size: 1vw;
    color: rgb(165, 201, 202);
    position: relative;
    padding-bottom: 5%;
}
.login-password-check-body input{
    background-color: transparent;
    border:1px solid white;
    border-radius: 2px;
    color: white;
    font-size: 0.8vw;
}
.login-password-check-body input:focus{
    outline: none;
}
.login-password-check-body button{
    background-color: transparent;
    border: 1px solid white;
    color:white;
    border-radius: 2px;
    font-size: 0.8vw;
    margin-left: 10px;
}
.access-key-error{
    font-size: 0.7vw;
    color: red;
    position: absolute;
    top:60%;
    left:25%;
}
