.output-details{
    background-color: rgba(148, 144, 144, 1);
    padding: 1vw 0;
}
.content-part,.content-part-img{
    width: 95%;
    margin: auto;
    font-size: 0.8vw;
    color:rgba(32, 34, 36, 1)
}
.content-part>div:first-child,.content-part-img>div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3.8%;
}
.content-part>div:first-child>div,.content-part-img>div:first-child>div{
    display: flex;
    align-items: center;
    gap:13px
}
.content-part>div:nth-child(2){
    display: flex;
    align-items: start;
    gap:10px;
    
    width: 100%;
    margin-top: 2.5%;
}
.content-part>div:nth-child(2)>div:nth-child(1){
    width: 12%;
    padding-top: 0.4%;
}
.content-part>div:nth-child(2)>div:nth-child(2) {
    width: 4.5%;
    border: 1px solid rgba(45, 52, 63, 1);
    padding: 0.3vw 1vw;
    border-radius: 3px;
}
.content-text{
    border:1px solid rgba(45, 52, 63, 1);
    min-width: 7vw;
    font-size: 0.8vw;
    text-align: center;
    border-radius: 3px;
    padding: 0.3vw 1vw;
    font-weight: bold;
}
.classes-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:10px;
    width: 79%;
}
.classes-grid>div{
    border:1px solid rgba(45, 52, 63, 1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    
}
.classes-grid>div>div:first-child{
    width: 10%;
    text-align: center;
    padding: 0.3vw 0;
}
.classes-grid>div>div:nth-child(2){
    border-left: 1px solid rgba(45, 52, 63, 1);
    width: 90%;
    padding: 0.3vw 0.4vw;
}
.image-value{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
}
.image-value>div{
    display: flex;
    justify-content: center;
    gap:10px;
    align-items: center;
}
.image-value>div>div:nth-child(2){
    border: 1px solid rgba(45, 52, 63, 1);
    border-radius: 3px;
    min-width: 3vw;
    padding: 0.3vw 0.4vw;
    display:flex ;
    align-items: center;
    justify-content: center;
}