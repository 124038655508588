.image-min-val,.image-max-val,.image-type,.image-max-threshold-val,.image-min-threshold-val,.image-precision-val{
    position: relative;
}
.label-head{
    margin-top: 10%;
}
.all-data-type{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 8.8vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    color:white;
    border: 1px solid #D0D5DD;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.all-data-type-close{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color:white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}
.min-max-image,.min-max-image-threshold{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:5%;
}
.min-max-image>div,
.min-max-image-threshold>div{
    width: 100%;
    box-sizing: border-box;
}
.min-max-image input,.min-max-image-threshold input{
    width: 100%;
    padding: 0.7vw;
    font-size: 0.8vw;
    border-radius: 4px;
    background: #383838;
    border: 1px solid #D0D5DD;
    color: white;
    box-sizing: border-box;
}
.min-max-image input:focus,.min-max-image-threshold input:focus{
    outline: none;
}
.selected-precesion{
    display: flex;
    padding: 2%;
    border-radius: 4px;
    background: #383838;
    color:white;
    justify-content: space-between;
    font-size: 0.8vw;
    border: 1px solid #D0D5DD;
    height: 1.5vw;
}
.selected-precesion>div{
    display: flex;
    align-items: center;
}
.list-precesion-open{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 10vw;
    /* opacity: 1; */
    background: #383838;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4px;
    overflow: hidden;
    color:white;
    border: 1px solid #D0D5DD;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;
}
.list-precesion-close{
    position: relative;
    font-size: 0.8vw;
    margin-top: 1%;
    max-height: 0px;
    overflow: hidden;
    /* opacity: 0; */
    background: #383838;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 1%;
    border-radius: 4px;
    color:white;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s;
}
input.no-spin::-webkit-inner-spin-button,
input.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.no-spin {
    -moz-appearance: textfield;
    /* For Firefox */
}