.image-galary{
    height: 80%;
    width: 80%;
    overflow: hidden;
    background: #1f262d;
    padding: 1vw 1.5vw;
    border-radius: 5px;
    text-align: center;
}
.image-galary>div{
    height: 100%;
}
.closing-btn-for-image-selction{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7%;
    width: 100%;
}
.adding-img-text{
    display: flex;
    align-items: center;
    gap:10px;
    font-size: 1vw;
    width: 40vw;
}
.closing-btn-for-image-selction button{
    background-color: transparent;
    color: white;
    border: none;
}
.images-box{
    width: 100%;
    height: 80%;
    overflow-y: auto;
    padding: 5px 5px;
    background: #333b43;
    margin-top: 1%;
}
.images-box img{
    /* max-height: 20vw;
    max-width: 15vw; 
     margin-bottom: 15px; */
}
.single-img-div{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}
.image-overlay,.image-overlay-not-to-be-selected{
    background: rgba(0, 0, 0, 0.616);
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.image-overlay-not-to-be-selected:hover{
    cursor: not-allowed;
}
.single-img-div .checkbox-icon-image-unchecked{
    position: absolute;
    top:5px;
    left:92%;
    color: white;
    border: none;
    height: min-content;
    z-index: 2;
}
.checkbox-icon-image-checked{
    position: absolute;
    top: 5px;
    left: 92%;
    color: white;
    border: none;
    height: min-content;
    z-index: 2;
}
.image-ext{
    position: absolute;
    top: 5px;
    left: 2%;
    color: white;
    border: none;
    height: min-content;
    z-index: 2;
}
.saving-image-btn{
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:10px
}
.saving-image-btn button:nth-child(1){
    background-color: rgb(255, 255, 255);
    color: black;
    font-weight: 600;
    font-size: 1.1vw;
    border: none;
    border-radius: 4px;
    padding: 5px 20px;
}
.saving-image-btn button:nth-child(2){
    background-color: blue;
    color: white;
    font-weight: 600;
    font-size: 1.1vw;
    border:none;
    border-radius: 4px;
    padding: 5px 20px;
}
.informative-icon{
    color: blue;
    font-size: 1.4vw;
    cursor: pointer;
    margin-top: 0.5vw;
}
.image-pop-preview{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-pop-preview-overlay{
    position: fixed;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
/* .image-pop-review-content{
    max-width:30%;
    z-index: 2;
    background: #1f262d;
    border-radius: 4px;
    max-height: 30vw;
} */
.skeleton-image-div{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:10px;
    height: 100%;
}
.image-overlay-for-hover{
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 55%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: ease-in-out 0.5s;
    
}
.image-overlay-for-hover-null{
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: ease-in-out 0.5s;
}
.image-details{
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 55%;
    transition: all ease-in-out 0.5s;
    transition-delay: 0.2s;
    opacity: 1;
    z-index: 4;
}
.image-details-null{
    position: absolute;
    bottom: 45%;
    left: 0;
    width: 100%;
    height: 0%;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
    opacity: 0;
    z-index: 4;
}
.image-details-heading{
    font-size: 0.9vw;
    font-weight: bold;
    color:rgb(61, 61, 255);
    margin-top: 2%;
}
.image-details-content{
    font-size: 0.7vw;
    text-align: left;
    margin-top: 1%;
    padding: 0% 2%;
}
.image-details-content>div{
    display: flex;
    align-items: center;
    gap:6px
}
.metadata-more-btn{
    width: 20%;
    font-size: 1vw;
    margin-top: 2%;
    margin-left: 80%;
    color:gold;
    text-decoration: underline;
}
.filter-div-for-img{
    position: relative;
    margin-left: 10%;
}
.selected-filter-for-img{
    border:2px solid rgba(207, 207, 207, 0.514);
    width: 13vw;
    padding: 0.4vw;
    border-radius: 0.4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9vw;
}
.selected-filter-for-img:hover{
    cursor: pointer;
}


.filter-options-for-img-open{
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #333b43;
    border-radius: 0.4vw;
    font-size: 0.9vw;
    max-height: 10vw;
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid rgba(207, 207, 207, 0.514);
    overflow-y: auto;
}
.filter-options-for-img-close{
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #333b43;
    border-radius: 0.4vw;
    overflow: hidden;
    font-size: 0.9vw;
    max-height: 0vw;
    overflow: hidden;
    opacity: 0.7;
    border: 0px solid rgba(207, 207, 207, 0.514);
    transition: all 0.5s;
}
.filter-options-div{
    
    border-radius: 0.4vw;
    overflow: auto;
}

.filter-options-div>div {
    padding: 0.4vw 0;
    border-radius: 0.4vw;
}

.filter-options-div>div:hover {
    background-color: #4b4e51;
    cursor: pointer;
}